export const ANNOUNCEMENT_TEXTS_LIST: Record<string, string> = {
    title: "ПРОТОКОЛ",
    subtitle: "затвердження річного плану закупівель",
    customer_info: "Найменування замовника:",
    customer_category: "Категорія замовника:",
    edr_id: "Ідентифікаційний код замовника в ЄДР:",
    customer_location: "Місцезнаходження замовника:",
    kind: "Kind",
    customer_edrpou: "Ідентифікаційний код замовника в ЄДР:",
    customer_organization_name: "Найменування організатора закупівлі:",
    customer_organization_category: "Категорія організатора закупівлі:",
    customer_organization_edr_id: "Ідентифікаційний код організатора закупівлі в ЄДР:",
    customer_organization_location: "Місцезнаходження організатора закупівлі:",
    type_of_purchase: "Вид закупівлі:",
    procurement_type: "Назва предмета закупівлі:",
    procuring_entity_code: "Код згідно з Єдиним закупівельним словником предмета закупівлі:",
    additional_procuring_entity_code: "Код економічної класифікації видатків бюджету:",
    expected_price: "Очікувана вартість предмета закупівлі:",
    procuring_entity_name: "Назва номенклатурної позиції предмета закупівлі",
    procuring_entity_code_table: "Код згідно з Єдиним закупівельним словником, що найбільше відповідає назві номенклатурної позиції предмета закупівлі",
    procurement_amount: "Кількість товарів або обсяг виконання робіт чи надання послуг",
    dk_2015: "ДК021-2015:",
    additional_code: "Код економічної класифікації видатків бюджету:",
    classifiers_names: "Назви відповідних класифікаторів предмета закупівлі (за наявності):",
    classifiers_names_table: "Назви відповідних класифікаторів предмета закупівлі (за наявності)",
    reasons_for_purchase_by_customer: "Обґрунтування підстав для здійснення замовником закупівлі:",
    has_been_resolved: "Вирішено:",
    has_been_resolved_text: "Затвердити річний план закупівлі та опублікувати на веб-порталі Уповноваженого органу",
    tender_start_date: "Орієнтовний початок проведення закупівлі:",
    source_funding: "Джерело фінансування закупівлі",
    description: "Опис",
    budget_breakdown_sum: "Сума",
};
