export const MARGIN_0 = 0;
export const MARGIN_3 = 3;
export const MARGIN_5 = 5;
export const MARGIN_10 = 10;
export const MARGIN_15 = 15;
export const MARGIN_20 = 20;
export const MARGIN_25 = 25;
export const MARGIN_30 = 30;
export const MARGIN_40 = 40;
export const MARGIN_50 = 50;
export const MARGIN_60 = 60;
export const MARGIN_70 = 70;
export const MARGIN_120 = 120;

export const LINE_HEIGHT_SMALL = 0.3;
export const LINE_HEIGHT_10 = 10;
export const LINE_HEIGHT_20 = 20;
export const LINE_HEIGHT_40 = 40;

export const EMPTY_FIELD = {};
export const EMPTY_FIELD_DASH = {text: "—"};

export const ROW_COUNT_2 = 2;
export const ROW_COUNT_4 = 4;
export const ROW_COUNT_6 = 6;
export const ROW_COUNT_10 = 10;
export const TWO_COLS = 2;

export const ROW_WIDTH_50 = 50;
export const ROW_WIDTH_65 = 65;
export const ROW_WIDTH_70 = 70;
export const ROW_WIDTH_75 = 75;
export const ROW_WIDTH_90 = 90;
export const ROW_WIDTH_95 = 95;
export const ROW_WIDTH_100 = 100;
export const ROW_WIDTH_110 = 110;
export const ROW_WIDTH_125 = 125;
export const ROW_WIDTH_130 = 130;
export const ROW_WIDTH_150 = 150;
export const ROW_WIDTH_180 = 180;
export const ROW_WIDTH_200 = 200;
export const ROW_WIDTH_245 = 245;
export const ROW_WIDTH_250 = 250;
export const ROW_WIDTH_270 = 270;
export const ROW_WIDTH_300 = 300;
export const ROW_WIDTH_340 = 340;
export const ROW_ALL_WIDTH = "*";
export const ROW_AUTO_WIDTH = "auto";

export const LINE_LENGTH = 595;

export const REQUEST_RESULT_IS_INFORMATION_PROVIDED = "0";

export const TABLE_LAYOUT_NO_BORDERS = "noBorders";

export const CLASSIFIED_ID_CHARACTERS = 4;
export const DELTA = 1;
export const CONTRACT_ID_TRIM_TO = 3;
export const PRICE_DECIMAL_PRECISION = 2;
export const GET_PERCENT = {
    TEN: 0.1,
    TWENTY: 0.2,
};

export const NULL_MARGIN = [
    0,
    0,
    0,
    0,
];
