export const DETERMINING_WINNER_OF_PROCUREMENT: Record<string, string> = {
    title: "ПРОТОКОЛ",
    subtitle: "визначення переможця закупівлі та намір укласти договір про закупівлю",
    customer_info: "Найменування замовника/організатора закупівлі:",
    customer_category: "Категорія замовника/організатора закупівлі:",
    customer_edrpou: "Ідентифікаційний код замовника/організатора закупівлі в ЄДР:",
    customer_location: "Місцезнаходження замовника/організатора закупівлі:",
    type_of_purchase: "Вид закупівлі:",
    procuring_entity_title: "Назва предмета закупівлі:",
    participants_name: "Найменування учасника (для юридичної особи) або прізвище, ім’я, по батькові (для фізичної особи) щодо якого приймається рішення:",
    id_code: "Ідентифікаційний код учасника:",
    awards_value_amount: "Ціна тендерної пропозиції/пропозиції учасника:",
    awards_weighted_value: "Приведена ціна тендерної пропозиції/пропозиції учасника:",
    awards_amount_performance_value: "Показник ефективності енергосервісного договору:",
    with_tax: "з ПДВ",
    has_been_resolved: "Вирішено:",
    has_been_resolved_text: "Визначити учасника переможцем закупівлі.\n\nПрийняти та затвердити рішення про намір укласти договір про закупівлю з переможцем та опублікувати повідомлення про намір укласти договір про закупівлю на веб-порталі Уповноваженого органу.",
    name_of_the_nomenclature_item_of_the_procurement: "Назва номенклатурної позиції предмета закупівлі",
    purchase_dictionary_code: "Код згідно з Єдиним закупівельним словником, що найбільше відповідає назві номенклатурної позиції предмета закупівлі",
    procurement_amount: "Кількість товарів або обсяг виконання робіт чи надання послуг",
    procurement_destination: "Місце поставки товарів або місце виконання робіт чи надання послуг",
    delivery_period: "Строк поставки товарів, виконання робіт чи надання послуг",
    dk_2015: "ДК 021:2015",
    suppliersIsNotDefined: "Учасники не визначені",
    awardNotFound: "В підписі не знайдено  інформацію ані про переможця, ані про тендер",
};
