export const EDR = {
    title: "Витяг",
    subtitle: "з Єдиного державного реєстру юридичних осіб, фізичних осіб-підприємців та громадських формувань",
    current_on: "Актуально на",
    legal_name: "Повне найменування юридичної особи/прізвище, ім'я по батькові фізичної особи",
    scheme: "Схема ідентифікації",
    identification_id: "Ідентифікаційний код юридичної особи",
    name: "Коротка назва організації",
    registration_status: "Стан суб'єкта",
    management: "Відомості про органи управління юридичної особи",
    address: "Місцезнаходження юридичної особи/місце проживання юридичної особи",
    founders_title: "Перелік засновників юридичної особи",
    founder_name: "Найменування",
    founder_code: "Ідентифікаційний код юридичної особи",
    founder_capital: "Капітал",
    founder_address: "Адреса",
    kind_title: "Види діяльності",
    main_kind: "Основний:",
    additional_kind: "Додаткові:",
};
