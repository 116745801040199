export enum PdfTypes {
    PQ = "pq",
    EDR = "EDR",
    NAZK = "nazk",
    TICKET = "ticket",
    COMPLAINT = "complaint",
    CONCLUSION = "conclusion",
    ANNOUNCEMENT = "announcement",
    DETERMINING_WINNER_OF_PROCUREMENT = "DETERMINING_WINNER_OF_PROCUREMENT",
    TENDER_REJECTION_PROTOCOL = "TENDER_REJECTION_PROTOCOL",
    PURCHASE_CANCELLATION_PROTOCOL = "PURCHASE_CANCELLATION_PROTOCOL",
    ANNUAL_PROCUREMENT_PLAN = "ANNUAL_PROCUREMENT_PLAN",
    PROTOCOL_CONSIDERATION_TENDER_OFFERS = "protocol_consideration_tender_offers",
    PROTOCOL_ON_EXTENSION_OF_REVIEW_PERIOD = "PROTOCOL_ON_EXTENSION_OF_REVIEW_PERIOD",
    TENDER_OFFER = "TENDER_OFFER",
}
