const DATE_LENGTH_SHORT = 2;
const DATE_LENGTH_FULL = 4;
const DAY_START = 0;
const MONTH_START = 2;
const YEAR_START = 4;

export class StringHandler {
    /**
     * "03032021" => "03.03.2021"
     */
    public static formatToDate(str: string): string {
        const day: string = str.substr(DAY_START, DATE_LENGTH_SHORT);
        const month: string = str.substr(MONTH_START, DATE_LENGTH_SHORT);
        const year: string = str.substr(YEAR_START, DATE_LENGTH_FULL);
        return `${day}.${month}.${year}`;
    }
}
