import {TypeChecker} from "~/utils/checker/TypeChecker";
import {EmptyChecker} from "~/utils/checker/EmptyChecker";
import {TypeCheckFactory} from "~/services/DataTypeValidator/TypeCheckFactory";
import {typeCheckStrategyMap} from "~/services/DataTypeValidator/TypeCheckStrategyMap";
import {PdfError} from "~/services/Error/PdfError";
import {messages} from "~/config/messages";
import {DataTypeValidatorInterface} from "~/services/DataTypeValidator/DataTypeValidatorInterface";

export class DataTypeValidator implements DataTypeValidatorInterface {
    private readonly typeChecker = new TypeChecker();
    private readonly emptyChecker = new EmptyChecker();
    private readonly factory = new TypeCheckFactory(
        typeCheckStrategyMap,
        this.typeChecker,
        this.emptyChecker,
    );

    public validate(data: any, typesList: string[] | string, errorMessage?: string): void {
        if (!this.typeChecker.isArray(typesList)) {
            typesList = [typesList as string];
        }

        const valid = (typesList as string[]).some((type: string) => this.checkType(data, type));

        if (!valid) {
            throw new PdfError(errorMessage || messages.error.incorrectInputFormat);
        }
    }

    private checkType(data: any, type: string): boolean {
        const validator = this.factory.create(type);
        return validator.validate(data);
    }
}
