import axios from "axios";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {Pdfmake} from "~/globals";
import {XMLParser} from "~/services/Dom/XMLParser";
import {PdfError} from "~/services/Error/PdfError";
import {Logger} from "~/utils/Logger";
import {PDFInterface} from "~/services/PDF/PDFInterface";
import {TenderResponseType} from "~/types/Tender/TenderResponseType";
import {DocumentManager} from "~/services/PDF/document/DocumentManager";
import {Base64} from "~/utils/Base64";
import {Assert} from "~/utils/Assert";
import {messages} from "~/config/messages";
import {PdfTypes} from "~/services/PDF/PdfTypes";
import {PdfConfigType} from "~/types/pdf/PdfConfigType";
import {LoaderManager} from "~/services/PDF/P7SLoader/LoaderManager";
import {PdfDocumentConfigType} from "~/types/pdf/PdfDocumentConfigType";
import {PdfObjectType} from "~/types/pdf/PdfObjectType";
import {DataTypeValidator} from "~/services/DataTypeValidator/DataTypeValidator";
import {ValidationTypes} from "~/services/DataTypeValidator/ValidationTypes";
import {EdsInterface} from "services/EdsInterface";
import {DictionaryCollector} from "~/services/DictionaryCollector/DictionaryCollector";
import {ENCODING} from "~/constants/encoding";
import {SignerType} from "types/sign/SignerType";
import {PdfTemplateTypes} from "~/services/PDF/PdfTemplateTypes";
import {fonts} from "~/fonts/times";
import {SIGN_TO_DOC_FRAME_ID, STRING} from "~/constants/string";
import {TemplateCodesEnum} from "~/types/PQ/TemplateCodes.enum";

export class PDF implements PDFInterface {
    public readonly TYPES = PdfTypes;
    public readonly TEMPLATES = TemplateCodesEnum;
    private readonly base64 = new Base64();
    private readonly logger = new Logger();
    private readonly xmlParser = new XMLParser(this.logger);
    private readonly documentManager = new DocumentManager(this.xmlParser);
    private readonly dataTypeValidator = new DataTypeValidator();
    private readonly pdf = pdfMake as Pdfmake;
    private readonly minimalUrlLength: number = 2;
    private object?: PdfObjectType;
    private documentType = PdfTypes.TICKET;
    private eds?: EdsInterface;

    public init(eds: EdsInterface): void {
        this.eds = eds;
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        pdfMake.vfs["Times-New-Roman-Regular.ttf"] = fonts["Times-Regular.ttf"];
        pdfMake.vfs["Times-New-Roman-Bold.ttf"] = fonts["Times-Bold.ttf"];
        pdfMake.vfs["Times-New-Roman-Italic.ttf"] = fonts["Times-Italic.ttf"];

        pdfMake.fonts = {
            Roboto: {
                normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
                bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
                italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
                bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
            },
            Times: {
                normal: "Times-New-Roman-Regular.ttf",
                bold: "Times-New-Roman-Bold.ttf",
                italics: "Times-New-Roman-Italic.ttf",
                bolditalics: "Times-New-Roman-Italic.ttf",
            },
        };
    }

    public async setConfig({url, type}: PdfConfigType): Promise<void> {
        try {
            this.documentType = type || this.documentType;
            if (type === PdfTypes.PQ && url === STRING.EMPTY) {
                return;
            }
            this.dataTypeValidator.validate(url, ValidationTypes.STRING, messages.error.undefinedUrl);
            const {data: {data: payload}}: TenderResponseType = await axios.get(url);
            this.object = payload;
        } catch (e) {
            throw new PdfError(e);
        }
    }

    public async open(config: PdfDocumentConfigType): Promise<void> {
        this.dataTypeValidator.validate(config.title, ValidationTypes.STRING);

        const document = await this.create(config);
        this.pdf.createPdf(document).open();
    }

    public async getIframe(config: PdfDocumentConfigType): Promise<void> {
        const documentFile = await this.create(config);

        this.pdf.createPdf(documentFile).getDataUrl(dataUrl => {
            const targetElement: HTMLElement = document.getElementById(SIGN_TO_DOC_FRAME_ID) as HTMLElement;
            const iframe = document.createElement("iframe");
            iframe.src = dataUrl;
            iframe.width = "100%";
            iframe.height = "100%";
            targetElement.appendChild(iframe);
        });
    }

    public async save(config: PdfDocumentConfigType): Promise<void> {
        this.dataTypeValidator.validate(config.title, ValidationTypes.STRING);

        const document = await this.create(config);
        this.pdf.createPdf(document).download(`${config.title}.pdf`);
    }

    private async create(config: PdfDocumentConfigType): Promise<Record<string, any>> {
        try {
            if (this.documentType === PdfTypes.PQ) {
                const signers = [] as SignerType[];
                const dictionaries = await new DictionaryCollector().load(PdfTemplateTypes.PQ);

                this.documentManager.setDocumentType(PdfTemplateTypes.PQ);
                return this.documentManager.getDocumentData(config.contractTemplateName || STRING.EMPTY, signers, dictionaries, STRING.EMPTY, this.object);
            }

            Assert.isDefined(this.object, messages.error.undefinedObject);
            Assert.isDefined(this.eds, messages.error.libraryInit);

            const loaderManager = new LoaderManager(this.base64, axios);
            loaderManager.setLoaderType(this.documentType);
            const {file, type, encoding, url} = await loaderManager.getData(this.object, config);
            let data: string = STRING.EMPTY;
            let signers = [] as SignerType[];
            if (type !== PdfTemplateTypes.NAZK) {
                ({data, signers} = await this.getDataFromSign(file, encoding, [], type));
            } else if (url) {
                ({data} = await axios.get(url));
                data = JSON.stringify(data);
            }
            if (type === PdfTemplateTypes.COMPLAINT) {
                const t = config.tender || "/test-data/complaints/tender.json";
                const {data: {data: payload}}: TenderResponseType = await axios.get(t);
                this.object = payload;
            }

            Assert.isDefined(data, messages.error.documentEncoding);
            const dictionaries = await new DictionaryCollector().load(type);

            this.documentManager.setDocumentType(type);
            return this.documentManager.getDocumentData(data, signers, dictionaries, url, this.object);
        } catch (e) {
            throw new PdfError((e as Error).message || e);
        }
    }

    private async getDataFromSign(file: string, encoding: ENCODING | undefined, checkedEncodings: Array<ENCODING>, type: string): Promise<{
        data: any
        signers: any
    }> {
        if (checkedEncodings.sort().toString() === Object.values(ENCODING).sort().toString()) {
            return Promise.resolve({data: null, signers: null});
        }
        Assert.isDefined(this.eds, messages.error.libraryInit);
        const response = await this.eds.verify(file, encoding);

        if (type === PdfTemplateTypes.KVT || type === PdfTemplateTypes.XML) {
            if (response && this.tryParseXMLObject(response["data"])) {
                return response;
            }
        } else if (response && this.tryParseJSONObject(response["data"])) {
            return response;
        }
        let leftEncodings = undefined;
        if (encoding) {
            checkedEncodings.push(encoding);
            leftEncodings = Object.values(ENCODING).filter(x => !checkedEncodings.includes(x));
        }

        return this.getDataFromSign(file, leftEncodings ? leftEncodings.pop() : undefined, checkedEncodings, type);
    }


    private tryParseJSONObject(jsonString: string): boolean {
        try {
            const o = JSON.parse(jsonString);
            return o && typeof o === "object";
        } catch (e) {
            return false;
        }
    }

    private tryParseXMLObject(xmlString: string): boolean {
        try {
            const parser = new DOMParser();
            const o = parser.parseFromString(xmlString, "application/xml");
            return Boolean(o && typeof o === "object");
        } catch (e) {
            return false;
        }
    }
}
