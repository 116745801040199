export const TENDER_OFFER = {
    title: "Тендерна пропозиція/пропозиція",
    subtitle: "про проведення відкритих торгів",
    name_of_the_nomenclature_item_of_the_procurement: "Найменування учасника (для юридичної особи) або прізвище, ім’я, по батькові (для фізичної особи):",
    participant_edrpou: "Ідентифікаційний код учасника:",
    participant_location: "Місцезнаходження учасника:",
    participant_contact_person: "Контактна особа учасника:",
    participant_classification: "Класифікація суб’єкта господарювання:",
    information_price_tender_offer: "Інформація щодо ціни тендерної пропозиції/пропозиції до початку аукціону:",
    information_price_tender_offer_before_start: "Інформація щодо приведеної ціни тендерної пропозиції/пропозиції до початку аукціону:",
    with_tax: "з ПДВ",
    subcontractor: "Субпідрядник:",
    non_price_criteria: "Нецінові критерії",
    information_about_energy_service_contract: "Інформація про енергосервісний договір",
    contract_term_table: "Строк дії договору:",
    performance_indicator_energy_service_contract_title: "Показник ефективності енергосервісного договору:",
    fixed_percentage_annual_payments_favor_of_participant_table: "Фіксований відсоток щорічних платежів на користь учасника, %:",
    amounts_of_annual_cost_reduction_of_customer_table: "Суми щорічного скорочення витрат замовника, грн:",
    confirmation_of_absence_of_grounds_for_refusal_participate: "Підтвердження відсутності підстав для відмови в участі у процедурі закупівлі",
    the_participant_confirms_that: "Учасник підтверджує, що:",
    answer_and_confirmation: "Відповідь та підтвердження:",
    confirmation_form: "Форма підтвердження:",
    evidence_title: "Назва:",
    evidence_description: "Опис:",
    evidence_document: "Документ:",
    confirmation_of_other_requirements_of_tender_documentation: "Підтвердження інших вимог тендерної документації",
    specification: "Специфікація",
    name_nomenclature_item_procurement_item: "Назва номенклатурної позиції предмета закупівлі",
    quantity_or_units_of_measurement: "Кількість/одиниці виміру",
    price_per_unit: "Ціна за одиницю",
    tender_documents: "Документи тендерної пропозиції/пропозиції",
    documents: "Документи:",
    detail: "Технічні характеристики:",
    technical_features: "Технічні характеристики:",
    localization_criteria: "Критерій локалізації:",
};
