import {LoaderStrategyInterface} from "~/services/PDF/P7SLoader/LoaderStrategyInterface";
import {AbstractLoaderStrategy} from "~/services/PDF/P7SLoader/AbstractLoaderStrategy";
import {P7SLoadResultType} from "~/types/pdf/P7SLoadResultType";
import {ERROR_MESSAGES} from "~/widgets/ErrorExceptionCore/configs/messages";
import {Assert} from "~/widgets/ErrorExceptionCore/Assert";
import {ENCODING} from "~/constants/encoding";
import {PdfDocumentConfigType} from "~/types/pdf/PdfDocumentConfigType";
import {PdfTemplateTypes} from "~/services/PDF/PdfTemplateTypes";
import {ArrayHandler} from "~/utils/ArrayHandler";
import {DocumentType} from "~/types/Tender/DocumentType";
import {AwardType} from "~/types/Tender/AwardType";


export class ProtocolOnExtensionOfReviewPeriodLoader extends AbstractLoaderStrategy implements LoaderStrategyInterface {
    public async load(object: AwardType, config: PdfDocumentConfigType): Promise<P7SLoadResultType> {
        const {documents} = object;
        Assert.isDefined(documents, ERROR_MESSAGES.VALIDATION_FAILED.documentListUndefined);
        const url = this.getDocumentUrl(documents, config);
        const file = await this.getData(url);
        return {
            file,
            type: PdfTemplateTypes.PROTOCOL_ON_EXTENSION_OF_REVIEW_PERIOD_TEMPLATE,
            encoding: config.encoding || ENCODING.UTF_16,
            url,
        };
    }

    private getDocumentUrl(documentList: DocumentType[], {date}: PdfDocumentConfigType): string {
        const documents = documentList.filter((doc: Record<string, any>) => doc.documentType === "extensionReport" && this.approximateCheckDateModified(doc.dateModified, date));
        const document = ArrayHandler.getLastElement(documents);
        Assert.isDefined(document, ERROR_MESSAGES.VALIDATION_FAILED.undefinedDocumentTitle);

        return document.url;
    }
}
