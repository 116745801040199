export const ANNOUNCEMENT_TEXTS_LIST: Record<string, string> = {
    title: "ОГОЛОШЕННЯ",
    subtitle: "про проведення відкритих торгів",
    underline_spaces: ".........................................................", // 56 spaces
    customer_info: "Найменування замовника",
    customer_edrpou: "Ідентифікаційний код замовника в ЄДР:",
    customer_location: "Місцезнаходження замовника:",
    customer_contact_person: "Контактна особа замовника, уповноважена здійснювати звʼязок з учасниками:",
    procurement_category: "Вид предмета закупівлі:",
    procuring_entity_name: "Назва номенклатурної позиції предмета закупівлі",
    procuring_entity_code: "Код згідно з Єдиним закупівельним словником, що найбільше відповідає назві номенклатурної позиції предмета закупівлі",
    procurement_amount: "Кількість товарів або обсяг виконання робіт чи надання послуг",
    procurement_destination: "Місце поставки товарів або місце виконання робіт чи надання послуг",
    delivery_period: "Строк поставки товарів, виконання робіт чи надання послуг",
    contract_terms: "Умови оплати:",
    contract_terms_event: "Подія",
    contract_terms_description: "Опис",
    contract_terms_paymentType: "Тип оплати",
    contract_terms_period: "Період,(днів)",
    contract_terms_daysType: "Тип днів",
    contract_terms_payment: "Розмір оплати, (%)",
    expected_price: "Очікувана вартість предмета закупівлі:",
    finance_source: "Джерело фінансування закупівлі:",
    minimal_step: "Розмір мінімального кроку пониження ціни:",
    formula: "Математична формула для розрахунку приведеної ціни (у разі її застосування):",
    deadline: "Кінцевий строк подання тендерних пропозицій:",
    securement: "Розмір надання забезпечення пропозицій учасників:",
    securement_type: "Вид та умови надання забезпечення пропозицій учасників:",
    disclosure_date: "Дата та час розкриття тендерних пропозицій:",
    auction_date: "Дата та час проведення електронного аукціону:",
    frame_contact_period: "Строк на який укладається рамкова угода:",
    frame_contact_amount: "Кількість учасників, з якими буде укладено рамкову угоду:",
    plans: "План, відповідно до якого проводиться закупівля (інформація про джерело фінансування)",
    purchasing_body: "Purchasing body",
    customer_category: "Категорія замовника",
    kind: "Kind",
    edr_id: "Ідентифікаційний код замовника в ЄДР",
    national_id: "National ID",
    contact_point: "Contact point",
    main_procurement_category: "Main procurement category",
    title_subject: "Title of the subject of purchase",
    purchase_dictionary_code: "Код за Єдиним закупівельним словником",
    cpv: "CPV",
    tender_language: "Мова тендерної пропозиції:",
    securement_conditions: "Умови надання забезпечення пропозицій учасників:",
    securement_type_conditions: "Вид та умови надання забезпечення пропозицій учасників:",
    securement_amount: "Розмір надання забезпечення пропозицій учасників:",
    securement_contract: "Розмір та умови надання забезпечення виконання договору про закупівлю:",
    procuring_entity_title: "Назва предмета закупівлі",
    procuring_entity_title_en: "Назва предмета закупівлі",
    feature: "PP = P/(1 + (F1 + F2 +... + Fn)/PV), де:\n" +
        "PP - приведена ціна;\n" +
        "P - ціна;\n" +
        "F1...Fn - питома вага інших критеріїв оцінки,\n" +
        "<br>запропонованих учасником;\n" +
        "PV - питома вага критерію “ціна”",
    missing_she: "відсутня",
    missing_he: "відсутній",
    missing_they: "відсутні",
    criterion_language: "CRITERION.OTHER.BID.LANGUAGE",
    criterion_guarantee: "CRITERION.OTHER.BID.GUARANTEE",
    contract_guarantee: "CRITERION.OTHER.CONTRACT.GUARANTEE",
    e_guarantee: "Електронна гарантія",
    dk_2015: "ДК 021:2015",
    dk_2015_en: "ДК 021:2015",
    dk_not_set: "Код ДК 021:2015 не вказаний",
    before: "до",
    after_auction_finish: "після завершення аукціону",
    signer: "Підписант:",
    sign_date: "Дата підпису:",
    original_doc: "Оригінал документа на веб-порталi\n уповноваженого органу",
    nbu_discount_rate: "Облікова ставка НБУ:",
    minimal_efectivity_step: "Мінімальний крок підвищення показника ефективності енергосервісного договору під час аукціону %",
    other_criterias: "Інші критерії, що застосовуються під час оцінки тендерних пропозицій, та їх питома вага (за наявності)",
    esco_financing: "Джерело фінансування",
    max_fixed_percent: "Максимальний фіксований відсоток платежів на користь учасника %",
    esco_sum: "Сума",
    esco_edrpou: "Код згідно з ЄДРПОУ замовника:",
    esco_concrete_name: "Конкретна назва предмета закупівлі",
    esco_classification_codes: "Коди відповідних класифікаторів предмета закупівлі (за наявності)",
    esco_funding_other: "фінансування з інших джерел",
    esco_funding_budget: "фінансування з бюджетних коштів",
};
