export type TextConfigType = Record<string, OlConfigType>;

export type OlConfigType = string | string[] | CompoundTextType;

export enum PdfItemEnum {
    TEXT="text",
    LIST_ITEM= "listItem"
}

export type CompoundTextType = {
    text: (string | number)[]
    paths: string[]
    defaults: string[]
    pdfType: PdfItemEnum
};

export type OlPdfType = {
    separator: string[]
    start: number
    ol: string[]
};

export type TableHeadConfigType = { text : string }[];
