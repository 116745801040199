import {LoaderStrategyInterface} from "~/services/PDF/P7SLoader/LoaderStrategyInterface";
import {AbstractLoaderStrategy} from "~/services/PDF/P7SLoader/AbstractLoaderStrategy";
import {P7SLoadResultType} from "~/types/pdf/P7SLoadResultType";
import {messages} from "~/config/messages";
import {Assert} from "~/utils/Assert";
import {ENCODING} from "~/constants/encoding";
import {PdfDocumentConfigType} from "~/types/pdf/PdfDocumentConfigType";
import {PdfTemplateTypes} from "~/services/PDF/PdfTemplateTypes";
import {DocumentType} from "~/types/Tender/DocumentType";
import {PdfError} from "~/services/Error/PdfError";

export class ProtocolConsiderationTenderOffersLoader extends AbstractLoaderStrategy implements LoaderStrategyInterface {
    public async load(document: DocumentType, config: PdfDocumentConfigType): Promise<P7SLoadResultType> {
        const {title, documentType, url, dateModified} = document;
        const {date} = config;
        Assert.isDefined(title, messages.error.documentListUndefined);
        Assert.isDefined(documentType, messages.error.wrongDocumentType);
        if (documentType !== "evaluationReports") {
            throw new PdfError(messages.error.wrongDocumentTypeStatus);
        }
        if (title !== "sign.p7s") {
            throw new PdfError(messages.error.wrongDocumentTitle);
        }
        if (!this.approximateCheckDateModified(dateModified, date)) {
            throw new PdfError(messages.error.wrongDocumentDate);
        }
        Assert.isDefined(document, messages.error.undefinedDocumentTitle);
        const file = await this.getData(url);
        return {
            file,
            type: PdfTemplateTypes.PROTOCOL_CONSIDERATION_TENDER_OFFERS_TEMPLATE,
            encoding: config.encoding || ENCODING.UTF_16,
            url,
        };
    }
}
