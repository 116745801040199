import {PQContractType} from "~/widgets/pq/types/PQTypes";
import {TemplateCodesEnum} from "~/widgets/pq/types/TemplateCodes.enum";
import {IPQBuilder} from "~/widgets/pq/PQBuilderInterface";
import {TenderOfferType} from "~/types/TenderOffer/Tender";
import {TemplateBuildHelper} from "~/widgets/pq/templates/TemplateBuildHelper";

export class FoodBuilder implements IPQBuilder {
    build(
        contractObject: PQContractType | Record<string, never>,
        contractTemplate: TemplateCodesEnum,
        tender: TenderOfferType | Record<string, any>,
    ): Record<string, any>[] {
        return TemplateBuildHelper.foodBuilder(contractObject, contractTemplate, tender);
    }
}
