import {XMLParserInterface} from "~/services/Dom/XMLParserInterface";
import {DocumentFactoryInterface} from "~/services/PDF/document/DocumentFactoryInterface";
import {DocumentStrategyInterface} from "~/services/PDF/document/DocumentStrategyInterface";
import {Assert} from "~/widgets/ErrorExceptionCore/Assert";
import {ERROR_MESSAGES} from "~/widgets/ErrorExceptionCore/configs/messages";
import {ERROR_CODES} from "~/widgets/ErrorExceptionCore/constants/ERROR_CODES.enum";

export class DocumentFactory implements DocumentFactoryInterface {
    constructor(
        private readonly typesMap: Map<string, new(xmlParser: XMLParserInterface) => DocumentStrategyInterface>,
        private readonly xmlParser: XMLParserInterface,
    ) {}

    public create(type: string): DocumentStrategyInterface {
        const strategyClass = this.typesMap.get(type);

        Assert.isDefined(strategyClass, ERROR_MESSAGES.SERVICE_UNAVAILABLE.typeIsNotDefined, ERROR_CODES.SERVICE_UNAVAILABLE);

        return new strategyClass(this.xmlParser);
    }
}
