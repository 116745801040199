export const PROTOCOL_CONSIDERATION_TENDER_OFFERS: Record<string, string> = {
    title: "ПРОТОКОЛ",
    subtitle: "розгляду тендерних пропозицій",
    customer_info: "Найменування замовника/організатора закупівлі:",
    customer_category: "Категорія замовника/організатора закупівлі:",
    customer_category_header_table: "Найменування замовника",
    customer_edrpou: "Ідентифікаційний код замовника/організатора закупівлі в ЄДР:",
    customer_edrpou_header_table: "Ідентифікаційний код замовника в ЄДР",
    customer_location: "Місцезнаходження замовника/організатора закупівлі:",
    customer_location_header_table: "Місцезнаходження замовника",
    type_of_purchase: "Вид закупівлі:",
    procuring_entity_title: "Назва предмета закупівлі:",
    list_tender_offers_for_lot: "Перелік тендерних пропозицій лоту",
    list_tender_offers: "Перелік тендерних пропозицій",
    name_of_the_nomenclature_item_of_the_procurement: "Найменування учасника (для юридичної особи) або прізвище, ім’я, по батькові (для фізичної особи)",
    result_of_consideration_of_tender_offer: "Результат розгляду тендерної пропозиції  (відхилення тендерної пропозиції/допущення до аукціону)",
    grounds_for_rejecting_tender: "Підстави та обґрунтування відхилення тендерної пропозиції (у разі відхилення)",
    has_been_resolved: "Вирішено:",
    has_been_resolved_text: "Прийняти та затвердити рішення щодо розгляду тендерних пропозицій на відповідність вимогам тендерної документації до проведення оцінки тендерних пропозицій та опублікувати протокол розгляду тендерних пропозицій на веб-порталі Уповноваженого органу.",
    qualifications_status_admitted_to_auction: "Допущено до аукціону",
    qualifications_status_rejected: "Відхилено",
    qualifications_status_decision_is_awaited: "Рішення очікується",
    qualifications_status_decision_is_overturned: "Рішення скасовано",
};
