import {AbstractDocumentStrategy} from "~/services/PDF/document/AbstractDocumentStrategy";
import {STRING} from "~/constants/string";
import {AnnouncementItem} from "~/types/Announcement/AnnouncementTypes";

export class UnitHelper {
    private strategy: AbstractDocumentStrategy;

    constructor(strategy: AbstractDocumentStrategy) {
        this.strategy = strategy;
    }

    public prepareUnitName(item: AnnouncementItem, recommendedDictionary: Record<string, any> | undefined): string {
        if (!this.strategy.emptyChecker.isEmptyString(this.strategy.getField(item, "unit.name"))) {
            return this.strategy.getField(item, "unit.name");
        }
        if (recommendedDictionary === undefined) {
            return STRING.DASH;
        }
        const unitDictionaryName = this.strategy.getField(recommendedDictionary, `${this.strategy.getField(item, "unit.code", "")}.name`, "");
        return this.strategy.emptyChecker.isEmptyString(unitDictionaryName) ? STRING.DASH : unitDictionaryName;
    }
    static currencyFormatting(currency: string|number): string {
        // 483500 -> 483 500,00
        const [full, decimal] = currency.toString().split(".");
        let decimal_part;
        if (decimal) {
            switch (true) {
                case decimal.length > 1:
                    decimal_part = decimal;
                    break;
                case decimal.length === 1:
                    decimal_part = `${decimal}0`;
                    break;
                default:
                    decimal_part = "00";
            }
        } else {
            decimal_part = "00";
        }

        return [full.replace(/\B(?=(\d{3})+(?!\d))/g, " "), decimal_part].join(",");
    }
}
