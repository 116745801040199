import {LoggerInterface} from "./LoggerInterface";
import {PREFIX} from "~/constants/prefixes";

export class Logger implements LoggerInterface {
    constructor(
        private readonly isDebug: boolean = false,
    ) {}

    log(message: any): void {
        if (!this.isDebug) {
            return;
        }

        console.log(PREFIX.SIGN_TO_DOC, message);
    }

    warn(message: any): void {
        if (!this.isDebug) {
            return;
        }

        console.warn(PREFIX.SIGN_TO_DOC, message);
    }

    error(error: Error | string): void {
        if (!this.isDebug) {
            return;
        }

        console.error(PREFIX.SIGN_TO_DOC, error);
    }

    success(message: any): void {
        if (!this.isDebug) {
            return;
        }

        console.log(`%c ${PREFIX.SIGN_TO_DOC} ${message} `, "color: green; font-weight: bold; background: white;");
    }
}
