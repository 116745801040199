export const procurementMethodTypeEU = [
    "aboveThresholdEU",
    "aboveThresholdUA.defense",
    "closeFrameworkAgreementUA",
    "competitiveDialogueEU",
];

export const noSecurement = [
    "belowThreshold",
    "aboveThreshold",
    "aboveThresholdUA",
    "aboveThresholdEU",
];

export const noAuction = [
    "competitiveDialogueUA",
    "competitiveDialogueEU",
];

export const closeFrame = ["closeFrameworkAgreementUA"];

export const qualificationPeriodTypes = [
    ...noAuction,
    "aboveThresholdEU",
    "competitiveDialogueUA.stage2",
    "competitiveDialogueEU.stage2",
];
