import {TextConfigType} from "~/types/PQ/TextConfigType";
import {TemplateCodesEnum} from "~/types/PQ/TemplateCodes.enum";
import {fruitTextsConfig} from "~/config/pdf/pq/contractTextConfigs/fruitTextsConfig";
import {computerTextsConfig} from "~/config/pdf/pq/contractTextConfigs/computersTextsConfig";
import {fuelTextsConfig} from "~/config/pdf/pq/contractTextConfigs/fuelTextsConfig";
import {pharmTextsConfig} from "~/config/pdf/pq/contractTextConfigs/pharmTextsConfig";
import {medicineTextsConfig} from "~/config/pdf/pq/contractTextConfigs/medicineTextsConfig";

export const TemplateToTextMap = new Map<string, TextConfigType>()
    .set(TemplateCodesEnum.FRUIT, fruitTextsConfig)
    .set(TemplateCodesEnum.COMPUTER, computerTextsConfig)
    .set(TemplateCodesEnum.OTHER, computerTextsConfig)
    .set(TemplateCodesEnum.MEDICINE, medicineTextsConfig)
    .set(TemplateCodesEnum.PHARM, pharmTextsConfig)
    .set(TemplateCodesEnum.GAS, fuelTextsConfig);
