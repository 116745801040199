import {LoaderStrategyInterface} from "~/services/PDF/P7SLoader/LoaderStrategyInterface";
import {AbstractLoaderStrategy} from "~/services/PDF/P7SLoader/AbstractLoaderStrategy";
import {P7SLoadResultType} from "~/types/pdf/P7SLoadResultType";
import {ERROR_MESSAGES} from "~/widgets/ErrorExceptionCore/configs/messages";
import {Assert} from "~/widgets/ErrorExceptionCore/Assert";
import {ENCODING} from "~/constants/encoding";
import {PdfDocumentConfigType} from "~/types/pdf/PdfDocumentConfigType";
import {PdfTemplateTypes} from "~/services/PDF/PdfTemplateTypes";
import {ArrayHandler} from "~/utils/ArrayHandler";
import {AnnouncementType} from "~/types/Announcement/AnnouncementTypes";
import {SIGNATURE_FILE_NAME} from "~/constants/string";

export class AnnouncementLoader extends AbstractLoaderStrategy implements LoaderStrategyInterface {
    async load(object: AnnouncementType, config: PdfDocumentConfigType): Promise<P7SLoadResultType> {
        const url = this.getDocumentUrl(object, config);
        const file = await this.getData(url);
        return {
            file,
            type: PdfTemplateTypes.ANNOUNCEMENT,
            encoding: config.encoding || ENCODING.UTF_16,
            url,
        };
    }

    private getDocumentUrl({documents}: AnnouncementType, {date}: PdfDocumentConfigType): string {
        Assert.isDefined(documents, ERROR_MESSAGES.VALIDATION_FAILED.documentListUndefined);

        const list = documents.filter((doc: Record<string, any>) => doc.title === SIGNATURE_FILE_NAME && this.approximateCheckDateModified(doc.dateModified, date));

        const document = ArrayHandler.getLastElement(list);

        Assert.isDefined(document, ERROR_MESSAGES.VALIDATION_FAILED.undefinedDocumentTitle);

        return document.url as string;
    }
}
