import {pharm2TextsConfig} from "~/widgets/pq/templates/pharm2/configs/pharm2Texts.config";
import {pqGenericBase, pqGenericTexts} from "~/widgets/pq/templates/generic/configs/pqGenericTexts";
import {DEFAULT_TEXT_FIELDS, STRING} from "~/constants/string";
import {PdfItemEnum, TextConfigType} from "~/widgets/pq/types/TextConfigType";
import {pqBase} from "~/widgets/pq/configs/pqTexts";
import {FormattingFunctionsEnum} from "~/widgets/pq/services/Formating/config/FormattingFunctions.enum";
import * as PDF_HELPER_CONST from "~/constants/pdf/pdfHelperConstants";

export const pharm2ContractConfig : TextConfigType = {
    predmet: [
        {
            text: [pharm2TextsConfig.predmet.postachalnik_zobovjazutsja_postaviti_ta_peredati, pqGenericTexts.subject.productClassificationID, 0],
            paths: ["items[0].classification.id"],
            defaults: [DEFAULT_TEXT_FIELDS.UNDERSCORES_16],
            pdfType: PdfItemEnum.LIST_ITEM,
        },
        pharm2TextsConfig.predmet.postachalnik_garantu_scho_na_moment,
        pharm2TextsConfig.predmet.storoni_pidtverdzhujut_scho__zrozumila,
        pharm2TextsConfig.predmet._razi_zastosuvannja_zabezpechennja_vikonannja,
        {
            text: [pqGenericTexts.subject.tender, 0, STRING.DELIMITER.DOT_NEW_LINE, pqGenericTexts.subject.note],
            paths: ["tenderID"],
            defaults: [DEFAULT_TEXT_FIELDS.UNDERSCORES_40],
            pdfType: PdfItemEnum.LIST_ITEM,
            functionName: [FormattingFunctionsEnum.TENDER_ID],
        },
    ],
    asortiment: [
        pharm2TextsConfig.asortiment.odinitsja_vimiru_kilkosti_tovaru_scho,
        pharm2TextsConfig.asortiment.zamovnik_ma_pravo_na_zmenshennja,
    ],
    jakist: [
        pharm2TextsConfig.jakist.postachalnik_garantu_scho_tovar_scho,
        pharm2TextsConfig.jakist.jakist_tovaru_pidtverdzhutsja_zokrema_deklaratsiju,
        pharm2TextsConfig.jakist._vipadkah_peredbachenih_chinnim_zakonodavstvom,
        pharm2TextsConfig.jakist.dokumenti_scho_zasvidchujut_jakist_ta,
        pharm2TextsConfig.jakist.termin_pridatnosti_tovaru_jakscho_tse,
        pharm2TextsConfig.jakist.nejakisnim_viznatsja_tovar_jakij,
        pharm2TextsConfig.jakist.postachalnik_zobovjazanij_povidomljati_zamovniku_budjaku,
        pharm2TextsConfig.jakist.storoni_zobovjazujutsja_negajno_ale_ne,
        {
            header: [pqBase.customer, pqBase.supplier],
            text: [pqBase.phone, pqBase.phone, pqBase.email, pqBase.email],
            paths: ["buyer.signerInfo.telephone", "suppliers[0].signerInfo.telephone", "buyer.signerInfo.email", "suppliers[0].signerInfo.email"],
            defaults: [DEFAULT_TEXT_FIELDS.UNDERSCORES_40, DEFAULT_TEXT_FIELDS.UNDERSCORES_40],
            widths: [PDF_HELPER_CONST.ROW_ALL_WIDTH, PDF_HELPER_CONST.ROW_ALL_WIDTH],
            pdfType: PdfItemEnum.TABLE,
        },
        pharm2TextsConfig.jakist.postachalnik_garantu_jakist__nadijnist,
        pharm2TextsConfig.jakist.garantijne_obslugovuvannja_poljaga__vikonanni,
        pharm2TextsConfig.jakist.dija_garantijnih_strokiv_ne_zalezhit,
        pharm2TextsConfig.jakist.postachalnik_zobovjazanij_za_svij_rahunok,
        pharm2TextsConfig.jakist.jakscho_protjagom_garantijnogo_stroku_tovar,
        pharm2TextsConfig.jakist.strok_zamini_remontu_tovaru_sklada,
        pharm2TextsConfig.jakist.na_period_chasu_protjagom_jakogo,
        pharm2TextsConfig.jakist.garantijni_umovi_ne_poshirjujutsja_na,
        pharm2TextsConfig.jakist.inshi_umovi_nadannja_garanti_na,
    ],
    tsina: [
        {
            text: [pqGenericTexts.price.amountDefinition, 0],
            paths: ["value.amount"],
            defaults: [DEFAULT_TEXT_FIELDS.DEFAULT_PRICE],
            pdfType: PdfItemEnum.LIST_ITEM,
            functionName: [FormattingFunctionsEnum.PRICE_WITH_TAX_TO_TEXT],
        },
        pharm2TextsConfig.tsina.tsina_za_odinitsju_tovaru_vstanovljutsja,
        pharm2TextsConfig.tsina.do_tsini_tovaru_vkljucheni_vsi,
    ],
    paymentOrder: [
        pharm2TextsConfig.paymentOrder.settlements,
        {
            text: [pqGenericTexts.paymentOrder.buyerPays, 0],
            paths: ["items"],
            defaults: [DEFAULT_TEXT_FIELDS.UNDERSCORES_16],
            pdfType: PdfItemEnum.LIST_ITEM,
            functionName: [FormattingFunctionsEnum.PAYMENT_DETAILS],
        },
        pharm2TextsConfig.paymentOrder.paymentDate,
        pharm2TextsConfig.paymentOrder.budgetCustomer,
        pharm2TextsConfig.paymentOrder.prepayment,
        pharm2TextsConfig.paymentOrder.notCommercialCredit,
    ],
    zasobi: [
        pharm2TextsConfig.zasobi.tovar_postachatsja__nalezhnij_tari,
        pharm2TextsConfig.zasobi.postachalnik_ma_upakuvati_tovar_,
        pharm2TextsConfig.zasobi.na_zovnishnomu_transportnomu_pakuvanni_tovaru,
        pharm2TextsConfig.zasobi.markuvannja_tovaru__spozhivcho_upakovki,
        pharm2TextsConfig.zasobi.pri_postavtsi_nemarkovanogo_abo_nenalezhno,
        pharm2TextsConfig.zasobi.zasobi_upakovki_tari_ta_pakuvannja,
        pharm2TextsConfig.zasobi.vartist_zasobiv_upakovki_tari_ta,
    ],
    porjadok: [
        pharm2TextsConfig.porjadok.strok_adresa_mistse_bazis_ta,
        pharm2TextsConfig.porjadok.postavka_tovaru__mistse_postavki,
        pharm2TextsConfig.porjadok._razi_najavnosti_vimog_virobnika,
        pharm2TextsConfig.porjadok.za_domovlenistju_storin_viznachenoju_,
        pharm2TextsConfig.porjadok.postachalnik_zobovjazanij_ne_piznishe_nizh,
        pharm2TextsConfig.porjadok.prijmannjaperedacha_tovapy_v_tomu_chisli,
        pharm2TextsConfig.porjadok.prijmannjaperedacha_tovapy__tomu_chisli,
        pharm2TextsConfig.porjadok.vrahovujuchi_spetsifiku_tovaru_jakij_postavljatsja,
        pharm2TextsConfig.porjadok.prijmannjaperedacha_tovapy_v_tomu_chisli2,
        pharm2TextsConfig.porjadok.prijmannjaperedacha_tovapy__tomu_chisli2,
        pharm2TextsConfig.porjadok.pislja_provedennja_vhidnogo_kontrolju_jakosti,
        pharm2TextsConfig.porjadok.tovar_vvazhatsja_peredanim_ta_perehodit,
        pharm2TextsConfig.porjadok._razi_vijavlennja_pri_prijmanni,
        pharm2TextsConfig.porjadok.jakscho_postachalnik_peredav_zamovniku_bilshu,
        pharm2TextsConfig.porjadok.postachalnik_razom__tovarom_zobovjazutsja,
        pharm2TextsConfig.porjadok._razi_vijavlennja_pid_chas,
        pharm2TextsConfig.porjadok.akt_vijavlenih_nedolikiv_skladatsja_protjagom,
        pharm2TextsConfig.porjadok._akti_vijavlenih_nedolikiv_zaznachajutsja,
        pharm2TextsConfig.porjadok.jakscho_postachalnik_na_moment_zakinchennja,
        pharm2TextsConfig.porjadok._razi_peredachi_postachalnikom_tovaru,
        pharm2TextsConfig.porjadok.jakscho_tovar_nenalezhno_jakosti_pidljaga,
        pharm2TextsConfig.porjadok.pidpisannjam_tsogo_dogovoru_storoni_pidtverdzhujut,
        pharm2TextsConfig.porjadok.jakscho_zastosovno_do_tovaru_scho,
        pharm2TextsConfig.porjadok.neobhidnist_provedennja_postachalnikom_puskonalagodzhuvalnih_robit,
    ],
    vidpovidalnist: [
        pharm2TextsConfig.vidpovidalnist._razi_nevikonannja_abo_nenalezhnogo,
        pharm2TextsConfig.vidpovidalnist._razi_porushennja_stroku_postavki,
        pharm2TextsConfig.vidpovidalnist._razi_porushennja_strokiv_postavki,
        pharm2TextsConfig.vidpovidalnist._razi_nadhodzhennja_pismovogo_zvernennja,
        pharm2TextsConfig.vidpovidalnist.za_postavku_tovaru_nenalezhno_jakosti,
        pharm2TextsConfig.vidpovidalnist._razi_zastosuvannja_peni_shtrafu_postachalnik,
        pharm2TextsConfig.vidpovidalnist._razi_jakscho_organami_derzhavno,
    ],
    obstavini: [
        pharm2TextsConfig.obstavini.kozhna_storona_zvilnjatsja_vid_vidpovidalnosti,
        pharm2TextsConfig.obstavini.storoni_rozumijut_ta_usvidomljujut_scho,
        pharm2TextsConfig.obstavini._ogljadu_na_fakti_vikladeni,
        pharm2TextsConfig.obstavini.strok_vikonannja_zobovjazan_storonoju_jaka,
        pharm2TextsConfig.obstavini.storona_jaka_postrazhdala_vid_di,
        pharm2TextsConfig.obstavini.storona_dlja_jako_utvorilisja_nemozhlivist,
        pharm2TextsConfig.obstavini.dlja_uniknennja_budjakih_sumniviv_storoni,
        pharm2TextsConfig.obstavini._razi_jakscho_vikonannja_dogovoru,
        pharm2TextsConfig.obstavini.storona_ne_ma_prava_posilatisja,
        pharm2TextsConfig.obstavini.pidpisannjam_tsogo_dogovoru_storoni_pidtverdzhujut,
    ],
    virishennja: [
        pharm2TextsConfig.virishennja.usi_spori_abo_rozbizhnosti_scho,
        pharm2TextsConfig.virishennja._vipadku_koli_storoni_ne,
        pharm2TextsConfig.virishennja.storona_jaka_porushila_prava_,
    ],
    antikoruptsijne: [
        pharm2TextsConfig.antikoruptsijne.pid_chas_vikonannja_svoh_zobovjazan,
        pharm2TextsConfig.antikoruptsijne._razi_nadhodzhennja_do_budjako,
        pharm2TextsConfig.antikoruptsijne.porushennja_vimog_antikoruptsijnogo_zakonodavstva_ukrani,
        pharm2TextsConfig.antikoruptsijne.storoni_garantujut_nalezhnij_rozgljad_predstavlenih,
        pharm2TextsConfig.antikoruptsijne.storoni_garantujut_povnu_konfidentsijnist_pid,
        pharm2TextsConfig.antikoruptsijne.zaznachene__tsomu_rozdili_antikoruptsijne,
    ],
    sanktsijni: [
        pharm2TextsConfig.sanktsijni.zamovnik_ma_pravo,
        pharm2TextsConfig.sanktsijni.zamovnik_ma_pravo_na_odnostoronnju,
        pharm2TextsConfig.sanktsijni.postachalnik_garantu_scho_tovar_ne,
        pharm2TextsConfig.sanktsijni.postachalnik_pidtverdzhu_scho_vin_ne,
    ],
    strok: [
        {
            text: [pqGenericTexts.contractAction.agreementValidTermStart, 0, pqGenericTexts.contractAction.agreementValidTermEnd],
            paths: ["period.endDate"],
            defaults: [pqBase.year],
            pdfType: PdfItemEnum.LIST_ITEM,
            functionName: [FormattingFunctionsEnum.CONVERT_DATE],
        },
        pharm2TextsConfig.strok.strok_di_dogovoru_ta_vikonannja,
        pharm2TextsConfig.strok.dija_dogovoru_pro_zakupivlju_mozhe,
    ],
    vnesennja: [
        pharm2TextsConfig.vnesennja.umovi_tsogo_dogovoru_ne_majut,
        pharm2TextsConfig.vnesennja.istotni_umovi_tsogo_dogovoru_ne,
        pharm2TextsConfig.vnesennja.zmini_ta_dopovnennja_do_tsogo,
        pharm2TextsConfig.vnesennja.storona_jaka_zatsikavlena__vnesenni,
        pharm2TextsConfig.vnesennja.zmini_do_tsogo_dogovoru_scho,
        pharm2TextsConfig.vnesennja.zamovnik_ma_pravo_dostrokovo_rozirvati,
        pharm2TextsConfig.vnesennja.rozirvannja_dogovoru_ne_zvilnja_storoni,
        pharm2TextsConfig.vnesennja.pri_rozirvanni_dogovoru_storoni_zobovjazani,
        pharm2TextsConfig.vnesennja._razi_jakscho_pislja_pidpisannja,
    ],
    konfidentsijna: [
        pharm2TextsConfig.konfidentsijna.budjaka_informatsija_pro_dijalnist_odni,
        pharm2TextsConfig.konfidentsijna.konfidentsijna_informatsija_ne_mozhe_buti,
        pharm2TextsConfig.konfidentsijna.kozhna_iz_storin_zobovjazutsja_pri,
        pharm2TextsConfig.konfidentsijna.postachalnik_ne_zaperechu_proti_otrimannja,
    ],
    inshi: [
        pharm2TextsConfig.inshi.tsej_dogovir_ukladeno__vidpovidnosti,
        pharm2TextsConfig.inshi.tsej_dogovir_reguljutsja_ta_tlumachitsja,
        pharm2TextsConfig.inshi.kozhna_storona_zapevnja_ta_garantu,
        {
            text: [pqGenericTexts.otherTerms.messages, STRING.DELIMITER.NEW_LINE, pqGenericBase.fromSupplier, 0, STRING.DELIMITER.NEW_LINE, pqGenericBase.fromBuyer, 1, STRING.DELIMITER.NEW_LINE, pqGenericTexts.otherTerms.messagesDetailsInAgreement],
            paths: ["suppliers[0].signerInfo.email", "buyer.signerInfo.email"],
            defaults: [DEFAULT_TEXT_FIELDS.UNDERSCORES_40, DEFAULT_TEXT_FIELDS.UNDERSCORES_40],
            pdfType: PdfItemEnum.LIST_ITEM,
        },
        pharm2TextsConfig.inshi.vikljuchno_dlja_tsilej_priskorennja_postavki,
        pharm2TextsConfig.inshi.dogovir_skladenij___dvoh,
        pharm2TextsConfig.inshi.zhodna_iz_storin_ne_vpravi,
        pharm2TextsConfig.inshi.nevidmnoju_chastinoju_tsogo_dogovoru_,
    ],
};
