import {PdfError} from "~/services/Error/PdfError";
import {TypeChecker} from "~/utils/checker/TypeChecker";

export class Assert {
    static isDefined<T>(value: T, message?: string, extra?: string): asserts value is NonNullable<T> {
        const typeChecker = new TypeChecker();

        if (typeChecker.isUndefined(value) || typeChecker.isNull(value)) {
            throw new PdfError(message, extra);
        }
    }
}
