export const additionalClassifications = {
    "CPV": "ДК 021:2015",
    "ДКПП": "ДК 016:2010",
    "ДК015": "ДК 015-97",
    "ДК018": "ДК 018-2000",
    "ДК003": "ДК003:2010",
    "КЕКВ": "Код КЕКВ",
    "ДК021": "ДК021-2015",
    "INN_NO_EU": "МНН",
    "INN_EU": "INN",
    "ATC_NO_EU": "АТХ",
    "ATC_EU": "ATC",
    "INN": "МНН",
    "ATC": "АТХ",
    "UA-ROAD": "Індекс автомобільних доріг",
    "GMDN": "НК 024:2019",
    "GMDN-2023": "НК 024:2023",
};
