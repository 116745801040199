import {AbstractDocumentStrategy} from "~/services/PDF/document/AbstractDocumentStrategy";
import {v4 as uuid} from "uuid";
import {PDF_FILED_KEYS} from "~/constants/pdf/pdfFieldKeys";
import {
    ANNOUNCEMENT_PAGE_MARGIN,
    TIME_NAMES,
} from "~/config/pdf/announcementConstants";
import {TENDER_OFFER} from "~/config/pdf/texts/TENDER_OFFER";
import * as PDF_HELPER_CONST from "~/constants/pdf/pdfHelperConstants";
import {SignerType} from "~/types/sign/SignerType";
import {MARGIN_TOP_10__BOTTOM_15} from "~/config/pdf/tenderOffer";
import {ESCO_TYPE, STRING} from "~/constants/string";
import {
    BidParametersType,
    BidsValueType, BidTendererType,
    BidType,
    CriterionType,
    EnumType,
    EvidenceType,
    FeatureType, ItemTableRowType,
    ItemType,
    LotValueType,
    ReferenceType,
    RequirementResponseType, RequirementType, SubCriteriaFieldsType,
    TenderOfferType,
} from "~/types/TenderOffer/Tender";
import {PdfError} from "~/services/Error/PdfError";
import {ContactPointType} from "~/types/Tender/ContactPointType";
import {DateHandler} from "~/utils/DateHandler";
import {UnitHelper} from "~/services/Common/UnitHelper";

export class TenderOfferDataMaker extends AbstractDocumentStrategy {
    private readonly HUNDRED_PERCENT: number = 100;
    private readonly NUMBER_OF_DECIMALS: number = 2;
    private readonly NUMBER_LENGTH_OF_YEAR: number = 4;
    private readonly MAX_TITLE_LENGTH = 40;
    private dictionaries: Map<string, Record<string, any>> | undefined;
    public create(file: string, _signers: SignerType[], dictionaries: Map<string, Record<string, any>>): Record<string, any>[] {
        if (!this.unwrapTender(file, true)) {
            return [];
        }

        const tender: TenderOfferType = this.unwrapTender(file) as TenderOfferType;
        const {criteria, features} = tender;
        const bidData = this.unwrapTender(file, true) as BidType;
        const {tenderers, parameters} = bidData;

        if (!tenderers) {
            throw new PdfError("Tenderers not found in the tender offer data");
        }

        this.dictionaries = dictionaries;
        const [bidTender] = tenderers;

        const tenderId = this.emptyChecker.isNotEmptyString(this.getField(tender, "tenderID")) ? this.getField(tender, "tenderID", "") : STRING.DASH;
        return [
            {
                style: PDF_FILED_KEYS.HEADING_TITLE,
                text: TENDER_OFFER.title,
            },
            {
                text: tenderId.concat("\n\n"),
                style: PDF_FILED_KEYS.TITLE_MEDIUM,
            },
            this.showWithDefault(this.getField(bidTender, "identifier.legalName") || this.getField(bidTender, "name"), TENDER_OFFER.name_of_the_nomenclature_item_of_the_procurement),
            this.prepareIdentifier(bidTender),
            this.showWithDefault(
                this.customerLocation(this.getField(bidTender, "address")),
                TENDER_OFFER.participant_location,
                Boolean(this.getField(bidTender, "address")),
            ),
            this.showWithDefault(
                this.contactPointTranslator(this.getField<ContactPointType>(bidTender, "contactPoint")),
                TENDER_OFFER.participant_contact_person,
                Boolean(this.getField(bidTender, "contactPoint")),
            ),
            this.showWithDefault(this.scaleResolver(this.getField(bidTender, "scale", ""), dictionaries.get("scale")), TENDER_OFFER.participant_classification),
            this.showIfAvailable(this.showBidsValue(bidData, "value", TENDER_OFFER.with_tax), TENDER_OFFER.information_price_tender_offer),
            this.showIfAvailable(this.showBidsValue(bidData, "weightedValue", TENDER_OFFER.with_tax), TENDER_OFFER.information_price_tender_offer_before_start),
            this.showIfAvailable(this.getField(bidData, "subcontractingDetails"), TENDER_OFFER.subcontractor),
            this.isNonLotsTender(bidData) ? this.escoTable(tender, bidData) : [PDF_HELPER_CONST.EMPTY_FIELD],
            this.buildParametersTable(parameters, features),
            ...this.resolveCriterionTables(criteria, bidData),
            this.isNonLotsTender(bidData) ? this.resolveSpecificationTable(tender, bidData) : [PDF_HELPER_CONST.EMPTY_FIELD],
            this.getTenderDocumentsTable(bidData),
            ...this.lotResolvesTable(tender, bidData),
        ];
    }

    getPageMargins(): number[] {
        return ANNOUNCEMENT_PAGE_MARGIN;
    }

    prepareQuantity(item: ItemType): string {
        if (!item.hasOwnProperty("quantity") || this.emptyChecker.isEmptyString(item.quantity)) {
            return STRING.DASH;
        }
        const quantity = this.getQuantity(item, "quantity");
        const {unit} = item;
        if (!unit) {
            return `${quantity}`;
        }
        const {name, code} = unit;
        if (name && name.length) {
            return `${quantity} ${name}`;
        }
        if (code.length) {
            const unitDictionary = this.dictionaries?.get("recommended");
            if (!unitDictionary) {
                return `${quantity}`;
            }
            const mapDict = new Map(Object.entries(unitDictionary));
            const unitName = mapDict.get(code).name ?? STRING.EMPTY;
            return `${quantity} ${unitName}`;
        }
        return `${quantity}`;
    }

    private prepareIdentifier(bidTender: BidTendererType): Record<string, any> {
        const id = this.getField(bidTender, "identifier.id", STRING.EMPTY);
        const scheme = this.getField(bidTender, "identifier.scheme", STRING.EMPTY);
        return this.showWithDefault(`${id} (${scheme})`, TENDER_OFFER.participant_edrpou, id !== STRING.EMPTY && scheme !== STRING.EMPTY);
    }

    private isNonLotsTender({lotValues}: BidType): boolean {
        return !(lotValues && lotValues.length > 0);
    }

    private resolveSpecificationTable(tender: TenderOfferType, bid: BidType, lot: LotValueType | undefined = undefined): Record<string, any> {
        const {items: bidItems, requirementResponses} = bid;
        const {items: tenderItems, criteria} = tender;
        if (!bidItems || bidItems.length === 0 || !tenderItems || tenderItems.length === 0) {
            return [PDF_HELPER_CONST.EMPTY_FIELD];
        }
        const items: ItemType[] = bidItems
            .filter(bidItem => tenderItems
                .find(
                    item => {
                        if (lot && lot.hasOwnProperty("id")) {
                            return item.relatedLot === lot.id && item.id === bidItem.id;
                        }
                        return item.id === bidItem.id;
                    },
                ),
            );

        if (!items || items.length === 0) {
            return [PDF_HELPER_CONST.EMPTY_FIELD];
        }
        const criterionList = criteria?.filter(criterion => criterion.hasOwnProperty("relatedItem") && items.find(item => criterion.relatedItem === item.id));
        return this.createSpecificationTable(items, criterionList, requirementResponses);
    }

    /**
     * Створення таблиці специфікації
     * Вона складається з 2-х частин:
     * 1. Основна частина, де вказується назва товару, кількість та ціна за одиницю
     * 2. Додаткова частина, де вказуються технічні характеристики товару
     *
     * @param items
     * @param criteria
     * @param requirementResponses
     * @private
     */
    private createSpecificationTable(items: ItemType[], criteria: CriterionType[] | undefined, requirementResponses: RequirementResponseType[] | undefined): Record<string, any> {
        /**
         * Основна частина таблиці, де вказується назва товару, кількість та ціна за одиницю
         */
        const mainTableAllRawRows : ItemTableRowType[] = this.getAllTableRowsFromItems(items, criteria, requirementResponses);
        if (mainTableAllRawRows.length === 0) {
            return [PDF_HELPER_CONST.EMPTY_FIELD];
        }
        const bodyRowsFinalTable: Record<string, any>[][] = [
            [
                {
                    text: TENDER_OFFER.name_nomenclature_item_procurement_item,
                    style: PDF_FILED_KEYS.TABLE_DATA_BOLD,
                },
                {
                    text: TENDER_OFFER.quantity_or_units_of_measurement,
                    style: PDF_FILED_KEYS.TABLE_DATA_BOLD,
                },
                {
                    text: TENDER_OFFER.price_per_unit,
                    style: PDF_FILED_KEYS.TABLE_DATA_BOLD,
                },
            ],
        ];
        /**
         * Якщо в таблиці є товари, які не мають додаткових характеристик, то вони виводяться в основну частину таблиці
         */
        const glueRowsOfFinalTables = mainTableAllRawRows.filter(({additional}) => additional === null);
        if (glueRowsOfFinalTables.length > 0) {
            glueRowsOfFinalTables.forEach(({mainRow}) => {
                bodyRowsFinalTable.push(mainRow);
            });
        }
        /**
         * Якщо в таблиці є товари, які мають додаткові характеристики, після рядків таблиці товарів, які не мають додаткових характеристик,
         */
        mainTableAllRawRows.forEach(row => {
            const {mainRow, additional, key} = row;
            const rowBelongsToGlueRows = glueRowsOfFinalTables.find(({key: glueKey}) => glueKey === key);
            if (rowBelongsToGlueRows) {
                return;
            }
            /**
             * Основний рядок з характеристиками
             */
            bodyRowsFinalTable.push(mainRow);
            /**
             * Рядок з надписом "Технічні характеристики" та додатковими характеристиками
             */
            bodyRowsFinalTable.push([
                {
                    colSpan: 3,
                    text: TENDER_OFFER.detail,
                    style: PDF_FILED_KEYS.FIELD_TEXT,
                },
                {
                    text: STRING.EMPTY,
                    style: PDF_FILED_KEYS.TABLE_DATA,
                },
                {
                    text: STRING.EMPTY,
                    style: PDF_FILED_KEYS.TABLE_DATA,
                },
            ]);
            /**
             * Додаткові рядки з характеристиками товару
             */
            bodyRowsFinalTable.push(...this.prepareAdditionalSpecificationFields(additional ?? []));
        });

        return this.resolveTableBug(
            {
                table: {
                    headerRows: 0,
                    dontBreakRows: false,
                    widths: [
                        PDF_HELPER_CONST.ROW_AUTO_WIDTH,
                        PDF_HELPER_CONST.ROW_AUTO_WIDTH,
                        PDF_HELPER_CONST.ROW_AUTO_WIDTH,
                    ],
                    body: bodyRowsFinalTable,
                },
                margin: MARGIN_TOP_10__BOTTOM_15,
            },
            {
                style: PDF_FILED_KEYS.TITLE_MEDIUM_BOLD,
                text: TENDER_OFFER.specification,
            },
        );
    }

    private getAllTableRowsFromItems(items: ItemType[], criteria: CriterionType[] | undefined, requirementResponses: RequirementResponseType[] | undefined) :ItemTableRowType[] {
        const mainTableRows: ItemTableRowType[] = [];
        items.forEach(item => {
            const mainData = [
                {
                    text: this.getField(item, "description", STRING.DASH),
                    style: PDF_FILED_KEYS.TABLE_HEAD,
                },
                {
                    text: `${this.prepareQuantity(item)}`,
                    style: PDF_FILED_KEYS.TABLE_HEAD,
                },
                {
                    text: this.showBidsValue(item, "unit.value", TENDER_OFFER.with_tax),
                    style: PDF_FILED_KEYS.TABLE_HEAD,
                },
            ];
            const additionalFields = this.getSpecificationsFieldsTable(item, criteria, requirementResponses);
            mainTableRows.push({key: uuid(), mainRow: mainData, additional: additionalFields});
        });
        return mainTableRows;
    }

    private prepareAdditionalSpecificationFields(specFields : SubCriteriaFieldsType[]): Record<string, any>[][] {
        if (!specFields || specFields.length === 0) {
            return [[PDF_HELPER_CONST.EMPTY_FIELD]];
        }
        const res: Record<string, any>[][] = [];
        specFields.forEach(field => {
            const {title, unit, value, values} = field;
            const unitName = unit === undefined ? STRING.EMPTY : this.getField(unit, "name", STRING.EMPTY);
            let fullTitle = `${title}`;
            if (unitName.length > 0) {
                fullTitle = `${fullTitle} (${unitName})`;
            }
            const valueText = value !== undefined && value.toString().length ? this.resolveRequirementResponsesValue(value) : STRING.DASH;
            const valuesText = values !== undefined && this.typeChecker.isArray(values) && values.length ? values.map((val: string) => this.resolveRequirementResponsesValue(val)).join(", ") : STRING.EMPTY;
            res.push(
                [
                    {
                        text: fullTitle,
                        style: PDF_FILED_KEYS.TABLE_DATA,
                    },
                    {
                        text: valuesText.length ? valuesText : valueText,
                        style: PDF_FILED_KEYS.TABLE_DATA,
                        colSpan: 2,
                    },
                    {
                        text: STRING.EMPTY,
                        style: PDF_FILED_KEYS.TABLE_DATA,
                    },
                ],
            );
        });
        return res;
    }

    private getSpecificationsFieldsTable(item: ItemType, criteria: CriterionType[] | undefined, requirementResponses: RequirementResponseType[] | undefined): SubCriteriaFieldsType[] | null {
        if (!requirementResponses || requirementResponses.length === 0 || !criteria || criteria.length === 0) {
            return null;
        }
        const subCriteriaFields = this.getSubCriteriaFields(item, criteria, requirementResponses);
        if (subCriteriaFields && subCriteriaFields.length > 0) {
            return subCriteriaFields;
        }
        return null;
    }

    private getSubCriteriaFields(item: ItemType, criteria: CriterionType[], requirementResponses: RequirementResponseType[]): SubCriteriaFieldsType[] {
        const {id} = item;
        const itemCriteria = criteria.filter(criterion => criterion.relatedItem === id);
        if (!itemCriteria || itemCriteria.length === 0) {
            return [];
        }
        const criteriaFields: SubCriteriaFieldsType[] = [];
        itemCriteria.forEach(criterion => {
            const {requirementGroups} = criterion;
            const requirements = requirementGroups.flatMap(group => group.requirements);
            requirements.forEach(requirement => {
                const {title, unit, id} = requirement;
                const requirementResponse = requirementResponses.find(response => response.requirement.id === id);
                if (!requirementResponse) {
                    return;
                }
                const {value, values} = requirementResponse;
                criteriaFields.push({
                    title,
                    unit,
                    value,
                    values,
                });
            });
        });
        return criteriaFields;
    }

    private resolveCriterionTables(criteria: CriterionType[] | undefined, bidType: BidType): Record<string, any>[] {
        if (!criteria || criteria.length === 0) {
            return [PDF_HELPER_CONST.EMPTY_FIELD];
        }
        const criterionOrders: { types: string[]; tableTitle: string; isLot: boolean }[] = [
            {
                types: ["CRITERION.EXCLUSION"],
                tableTitle: TENDER_OFFER.confirmation_of_absence_of_grounds_for_refusal_participate,
                isLot: false,
            },
            {
                types: ["CRITERION.OTHER.BID.LANGUAGE", "CRITERION.OTHER.BID.GUARANTEE", "CRITERION.OTHER.CONTRACT.GUARANTEE", "CRITERION.OTHER.LIFE_CYCLE_COST"],
                tableTitle: TENDER_OFFER.confirmation_of_other_requirements_of_tender_documentation,
                isLot: false,

            },
        ];
        return criterionOrders.map(criterionData => this.createCriterionTable(criterionData, criteria, bidType));
    }

    private createCriterionTable(criterionData: {types: string[]; tableTitle: string; isLot: boolean}, criteria: CriterionType[], bid: BidType): Record<string, any> {
        const {types, tableTitle, isLot} = criterionData;
        const {requirementResponses} = bid;
        if (!requirementResponses || requirementResponses.length === 0 || !criteria || criteria.length === 0) {
            return [PDF_HELPER_CONST.EMPTY_FIELD];
        }
        const tableRows: Record<string, any>[] = [];
        types.forEach(type => {
            requirementResponses.forEach(reqRes => {
                const {requirement, value, evidences} = reqRes;
                const criterion: CriterionType | undefined = criteria.find((criterion: CriterionType) => {
                    if (!isLot && criterion.relatesTo === "lot") {
                        return false;
                    }
                    if (isLot && criterion.relatesTo !== "lot") {
                        return false;
                    }

                    const {requirementGroups, classification} = criterion;
                    if (!classification || !requirementGroups || !requirementGroups.length || classification.id.indexOf(type) === -1) {
                        return false;
                    }
                    return requirementGroups.flatMap(group => group.requirements).find(groupRequirement => groupRequirement.id === requirement.id);
                });
                if (!criterion) {
                    return;
                }
                const requirementGroupRaw = criterion.requirementGroups.flatMap(group => group.requirements.find(req => req.id === requirement.id)).filter(el => el !== undefined);
                if (!requirementGroupRaw || !requirementGroupRaw.length) {
                    return;
                }
                const [requirementGroup]: (RequirementType | undefined)[] = requirementGroupRaw;
                const {title} = criterion;
                const criterionDescription: string = requirementGroup ? this.getField(requirementGroup, "description", STRING.EMPTY) : STRING.EMPTY;
                const requirementGroupTitle = requirementGroup ? this.getField(requirementGroup, "title", STRING.EMPTY) : STRING.EMPTY;
                const responseText: Record<string, any>[] = this.resolveEvidenceData(evidences, bid, value);
                if (responseText.length === 0) {
                    return;
                }
                const preparedTitle: Record<string, any>[] = [
                    {
                        text: `${title}\n`,
                        style: PDF_FILED_KEYS.TABLE_HEAD,
                    },
                    {
                        text: `${requirementGroupTitle}\n`,
                        style: PDF_FILED_KEYS.TABLE_DATA,
                    },
                    {
                        text: criterionDescription,
                        style: PDF_FILED_KEYS.TABLE_DATA,
                    },
                ];
                tableRows.push([preparedTitle, responseText]);
            });
        });
        if (tableRows.length === 0) {
            return [PDF_HELPER_CONST.EMPTY_FIELD];
        }
        // Header row for the table
        tableRows.unshift([
            {
                text: TENDER_OFFER.the_participant_confirms_that,
                style: PDF_FILED_KEYS.TABLE_HEAD,
            },
            {
                text: TENDER_OFFER.answer_and_confirmation,
                style: PDF_FILED_KEYS.TABLE_HEAD,
            },
        ]);
        return this.resolveTableBug(
            {
                table: {
                    headerRows: 0,
                    dontBreakRows: false,
                    widths: [
                        PDF_HELPER_CONST.ROW_WIDTH_250,
                        PDF_HELPER_CONST.ROW_ALL_WIDTH,
                    ],
                    body: tableRows,
                },
                margin: MARGIN_TOP_10__BOTTOM_15,
            },
            {
                style: PDF_FILED_KEYS.TITLE_MEDIUM_BOLD,
                text: tableTitle,
            },
        );
    }

    private resolveEvidenceData(evidences: EvidenceType[] | undefined, bid: BidType, value: string | undefined): Record<string, any>[] {
        const response = [];
        const preparedValue = this.resolveRequirementResponsesValue(value ?? STRING.EMPTY);
        if (this.emptyChecker.isNotEmptyString(String(preparedValue))) {
            response.push(
                {
                    text: `${preparedValue}\n\n`,
                    style: PDF_FILED_KEYS.TABLE_DATA_BOLD,
                },
            );
        }
        evidences?.forEach(evidence => {
            const {type, title, description, relatedDocument} = evidence;
            if (type) {
                response.push({
                    text: [
                        {
                            text: TENDER_OFFER.confirmation_form + STRING.WHITESPACE,
                            style: PDF_FILED_KEYS.TABLE_DATA_BOLD,
                        },
                        {
                            text: `${this.resolveEvidenceType(this.getField(evidence, "type", STRING.EMPTY))}\n`,
                            style: PDF_FILED_KEYS.TABLE_DATA,
                        },
                    ],
                });
            }
            if (title) {
                response.push({
                    text: [
                        {
                            text: TENDER_OFFER.evidence_title + STRING.WHITESPACE,
                            style: PDF_FILED_KEYS.TABLE_DATA_BOLD,
                        },
                        {
                            text: `${this.getField(evidence, "title", STRING.EMPTY)}\n`,
                            style: PDF_FILED_KEYS.TABLE_DATA,
                        },
                    ],
                });
            }
            if (description) {
                response.push({
                    text: [
                        {
                            text: TENDER_OFFER.evidence_description + STRING.WHITESPACE,
                            style: PDF_FILED_KEYS.TABLE_DATA_BOLD,
                        },
                        {
                            text: `${this.getField(evidence, "description", STRING.EMPTY)}\n`,
                            style: PDF_FILED_KEYS.TABLE_DATA,
                        },
                    ],
                });
            }
            if (relatedDocument) {
                const documents = this.findEvidenceDocumentTitle(relatedDocument, bid);
                response.push({
                    text: [
                        {
                            text: TENDER_OFFER.evidence_document + STRING.WHITESPACE,
                            style: PDF_FILED_KEYS.TABLE_DATA_BOLD,
                        },
                        {
                            text: documents,
                            style: PDF_FILED_KEYS.TABLE_DATA_BLUE,
                        },
                    ],
                });
            }
        });
        return response;
    }

    private findEvidenceDocumentTitle(evidenceDocument: ReferenceType, bid: BidType): Record<string, any>[] {
        const {documents, financialDocuments, eligibilityDocuments, qualificationDocuments} = bid;
        const allDocuments = [...documents ?? [], ...financialDocuments ?? [], ...eligibilityDocuments ?? [], ...qualificationDocuments ?? []];

        return allDocuments
            .filter(document => document.id === evidenceDocument.id && document.title !== "sign.p7s")
            .map(document => ({
                text: this.getField(evidenceDocument, "title", STRING.EMPTY),
                link: this.getField(document, "url", STRING.EMPTY),
                style: PDF_FILED_KEYS.TABLE_DATA_BLUE,
            }));
    }

    private resolveEvidenceType(type: string): string {
        switch (type) {
            case "document":
                return "Документ";
            case "statement":
                return "Заява";
            default:
                return type;
        }
    }

    private resolveRequirementResponsesValue(value: string | undefined): string {
        if (undefined === value) {
            return STRING.EMPTY;
        }
        console.log(value);
        switch (value.toString().toLowerCase()) {
            case "true":
                return "Так";
            case "false":
                return "Ні";
            default:
                return value;
        }
    }

    private escoTable(tender: TenderOfferType, bidType: BidType, lot: LotValueType | undefined = undefined): Record<string, any> {
        const {procurementMethodType} = tender;
        if (procurementMethodType !== ESCO_TYPE) {
            return [PDF_HELPER_CONST.EMPTY_FIELD];
        }
        const bidEntity: BidType | LotValueType = lot ?? bidType;
        const bidValueObject: BidsValueType | undefined = bidEntity.hasOwnProperty("value") ? bidEntity.value : undefined;
        if (!bidValueObject || this.emptyChecker.isEmptyObject(bidValueObject)) {
            return [PDF_HELPER_CONST.EMPTY_FIELD];
        }

        const body: Record<string, any>[][] = [];
        const {
            contractDuration,
            amountPerformance,
            currency,
            yearlyPaymentsPercentage,
            annualCostsReduction,
        } = bidValueObject;

        if (undefined !== contractDuration) {
            const {years, days} = contractDuration;
            body.push(
                [
                    {
                        text: TENDER_OFFER.contract_term_table,
                        style: PDF_FILED_KEYS.TABLE_HEAD,
                    },
                    {
                        text: `${DateHandler.timeToStr(years, TIME_NAMES.Years)} ${DateHandler.timeToStr(days, TIME_NAMES.Days)}`,
                        style: PDF_FILED_KEYS.TABLE_DATA,
                    },
                ],
            );
        }
        if (undefined !== amountPerformance || undefined !== currency) {
            body.push(
                [
                    {
                        text: TENDER_OFFER.performance_indicator_energy_service_contract_title,
                        style: PDF_FILED_KEYS.TABLE_HEAD,
                    },
                    {
                        text: this.showBidsValue(bidEntity, "amountPerformance", TENDER_OFFER.with_tax),
                        style: PDF_FILED_KEYS.TABLE_DATA,
                    },
                ],
            );
        }
        if (undefined !== yearlyPaymentsPercentage) {
            body.push(
                [
                    {
                        text: TENDER_OFFER.fixed_percentage_annual_payments_favor_of_participant_table,
                        style: PDF_FILED_KEYS.TABLE_HEAD,
                    },
                    {
                        text: `${Number(yearlyPaymentsPercentage * this.HUNDRED_PERCENT).toFixed(this.NUMBER_OF_DECIMALS).replace(/\./g, ",")} %`,
                        style: PDF_FILED_KEYS.TABLE_DATA,
                    },
                ],
            );
        }
        if (undefined !== annualCostsReduction) {
            body.push(
                [
                    {
                        text: TENDER_OFFER.amounts_of_annual_cost_reduction_of_customer_table,
                        style: PDF_FILED_KEYS.TABLE_HEAD,
                    },
                    {
                        text: this.prepareAnnualCostsReduction(annualCostsReduction, this.getStartYear(tender)),
                        style: PDF_FILED_KEYS.TABLE_DATA,
                    },
                ],
            );
        }

        return this.resolveTableBug(
            {
                table: {
                    headerRows: 0,
                    dontBreakRows: false,
                    widths: [
                        PDF_HELPER_CONST.ROW_WIDTH_250,
                        PDF_HELPER_CONST.ROW_ALL_WIDTH,
                    ],
                    body,
                },
                margin: MARGIN_TOP_10__BOTTOM_15,
            },
            {
                style: PDF_FILED_KEYS.TITLE_MEDIUM_BOLD,
                text: TENDER_OFFER.information_about_energy_service_contract,
            },
        );
    }

    private getStartYear(tender: TenderOfferType): number {
        const {tenderID} = tender;
        if (!tenderID) {
            return 0;
        }
        const year = tenderID.split("-").find(el => el.length === this.NUMBER_LENGTH_OF_YEAR);
        return year ? parseInt(year, 10) : 0;
    }

    private prepareAnnualCostsReduction(annualCostsReduction: number[], startYear: number): string {
        if (annualCostsReduction.length === 0) {
            return STRING.DASH;
        }
        return annualCostsReduction.map(cost => `${startYear++} ${STRING.MINUS} ${UnitHelper.currencyFormatting(cost)}`).join("\n");
    }

    private buildParametersTable(parameters: BidParametersType[] | undefined, features: FeatureType[] | undefined): Record<string, any> {
        if (!parameters || parameters.length === 0 || !features || features.length === 0) {
            return [PDF_HELPER_CONST.EMPTY_FIELD];
        }

        const body: Record<string, any>[][] = [];
        parameters.forEach((parameter: BidParametersType) => {
            const feature = features.find((feature: FeatureType) => feature.code === parameter.code);
            if (!feature) {
                return;
            }
            const titleEnum = feature.enum.find((enumItem: EnumType) => enumItem.value === parameter.value);
            const title = titleEnum ? titleEnum.title : STRING.DASH;
            body.push(
                [
                    {
                        text: this.getField(feature, "description", STRING.DASH),
                        style: PDF_FILED_KEYS.TABLE_HEAD,
                    },
                    {
                        text: title,
                        style: PDF_FILED_KEYS.TABLE_DATA,
                    },
                ],
            );
        });

        return this.resolveTableBug(
            {
                table: {
                    headerRows: 0,
                    dontBreakRows: false,
                    widths: [
                        PDF_HELPER_CONST.ROW_WIDTH_250,
                        PDF_HELPER_CONST.ROW_ALL_WIDTH,
                    ],
                    body,
                },
                margin: MARGIN_TOP_10__BOTTOM_15,
            },
            {
                style: PDF_FILED_KEYS.TITLE_MEDIUM_BOLD,
                text: TENDER_OFFER.non_price_criteria,
            },
        );
    }

    private showBidsValue(bid: BidType | ItemType, fieldName: string, withTaxText: string): string {
        const realFieldName = fieldName === "amountPerformance" || fieldName === "value" ? "value" : `${fieldName}`;
        const amountSelector = fieldName !== "amountPerformance" ? `${fieldName}.amount` : "value.amountPerformance";
        const currencySelector = `${realFieldName}.currency`;
        let amountValue: string | undefined = this.getField(bid, amountSelector);
        if (!amountValue) {
            return STRING.EMPTY;
        }
        amountValue = UnitHelper.currencyFormatting(amountValue);
        let text = `${amountValue} ${this.getField(bid, currencySelector)}`;
        const hasTax = this.getField(bid, `${realFieldName}.valueAddedTaxIncluded`);
        if (true === hasTax) {
            text += ` ${withTaxText}`;
        }
        return text;
    }

    private scaleResolver(scale: string, scaleDictionary: Record<string, any> | undefined): string {
        if (!scaleDictionary || this.emptyChecker.isEmptyString(scale)) {
            return STRING.DASH;
        }
        return this.getField(scaleDictionary, `${scale}.title`, "");
    }

    private contactPointTranslator(contactPoint: ContactPointType): string {
        const parts = ["name", "telephone", "faxNumber", "email", "url"];

        return parts.reduce((result, item): string => {
            const value = this.getField<string>(contactPoint, item);

            if (result && value) {
                return `${result}\n ${value}`;
            }
            return value ? value : result;
        }, "");
    }


    private getTenderDocumentsTable(bidType: BidType, lot: LotValueType | undefined = undefined): Record<string, any> {
        const {documents, financialDocuments, eligibilityDocuments, qualificationDocuments} = bidType;
        const allDocuments = [...documents ?? [], ...financialDocuments ?? [], ...eligibilityDocuments ?? [], ...qualificationDocuments ?? []]
            .filter(document => {
                if (lot && lot.hasOwnProperty("id")) {
                    return document.documentOf === "lot" && document.title !== "sign.p7s" && document.relatedItem === lot.id;
                }
                return document.documentOf === "tender" && document.title !== "sign.p7s";
            })
            .map(document => ({
                text: `${this.cutLongTitle(this.getField(document, "title", STRING.DASH))}\n`,
                link: this.getField(document, "url", STRING.EMPTY),
                style: PDF_FILED_KEYS.TABLE_DATA,
            }));

        if (allDocuments.length === 0) {
            return [PDF_HELPER_CONST.EMPTY_FIELD];
        }
        const body: Record<string, any>[] = [];
        body.push([
            {
                text: TENDER_OFFER.documents,
                style: PDF_FILED_KEYS.TABLE_HEAD,
            },
            {
                text: allDocuments,
                style: PDF_FILED_KEYS.TABLE_DATA_BLUE,
            },
        ]);

        return this.resolveTableBug(
            {
                table: {
                    headerRows: 0,
                    dontBreakRows: false,
                    widths: [
                        PDF_HELPER_CONST.ROW_WIDTH_245,
                        PDF_HELPER_CONST.ROW_WIDTH_245,
                    ],
                    body,
                },
                margin: MARGIN_TOP_10__BOTTOM_15,
            },
            {
                style: PDF_FILED_KEYS.TITLE_MEDIUM_BOLD,
                text: TENDER_OFFER.tender_documents,
            },
        );
    }

    private lotResolvesTable(tender: TenderOfferType, bidType: BidType): Record<string, any>[] {
        const {lotValues} = bidType;
        const {lots, criteria} = tender;
        if (!lotValues || lotValues.length === 0 || !lots || lots.length === 0) {
            return [PDF_HELPER_CONST.EMPTY_FIELD];
        }
        return lotValues.map(lotValue => {
            const lot = lots.find(lot => lot.id === lotValue.relatedLot);
            if (!lot) {
                return [PDF_HELPER_CONST.EMPTY_FIELD];
            }
            const criterionList = criteria?.filter(criterion => criterion.relatedItem === lot.id);
            return this.createBidLotTables(lot, lotValue, bidType, criterionList, tender);
        });
    }

    private createBidLotTables(lot: LotValueType, lotValueBid: LotValueType, bid: BidType, criterionList: CriterionType[] | undefined, tender: TenderOfferType): Record<string, any>[] {
        const {title} = lot;
        const criterionData = {
            types: ["CRITERION.OTHER.BID.GUARANTEE", "CRITERION.OTHER.CONTRACT.GUARANTEE", "CRITERION.OTHER.LIFE_CYCLE_COST"],
            tableTitle: TENDER_OFFER.confirmation_of_other_requirements_of_tender_documentation,
            isLot: true,
        };
        return [
            {
                style: PDF_FILED_KEYS.TITLE_LARGE_TENDER_OFFER,
                text: `Лот - ${title}`,
            },
            this.showWithDefault(this.showBidsValue(lotValueBid, "value", TENDER_OFFER.with_tax), TENDER_OFFER.information_price_tender_offer_lots),
            this.showWithDefault(this.showBidsValue(lotValueBid, "weightedValue", TENDER_OFFER.with_tax), TENDER_OFFER.information_quoted_price_tender_offer),
            this.showIfAvailable(this.getField(lotValueBid, "subcontractingDetails"), TENDER_OFFER.subcontractor),
            this.escoTable(tender, bid, lotValueBid),
            criterionList !== undefined ? this.createCriterionTable(criterionData, criterionList, bid) : [PDF_HELPER_CONST.EMPTY_FIELD],
            this.resolveSpecificationTable(tender, bid, lot),
            this.getTenderDocumentsTable(bid, lot),
        ];
    }

    private cutLongTitle(title: string): string {
        if (title.length > this.MAX_TITLE_LENGTH) {
            return title.substring(0, this.MAX_TITLE_LENGTH).concat("...");
        }
        return title;
    }
}
