import {TemplateCodesEnum} from "~/types/PQ/TemplateCodes.enum";
import {pqFuelTitles, pqGeneralTitles, pqMedicineTitles} from "~/config/pdf/pq/pqTitles";

export const TemplateToPqTitlesMap = new Map<string, Record<string, string>>()
    .set(TemplateCodesEnum.FRUIT, pqGeneralTitles)
    .set(TemplateCodesEnum.COMPUTER, pqGeneralTitles)
    .set(TemplateCodesEnum.OTHER, pqGeneralTitles)
    .set(TemplateCodesEnum.MEDICINE, pqMedicineTitles)
    .set(TemplateCodesEnum.PHARM, pqMedicineTitles)
    .set(TemplateCodesEnum.GAS, pqFuelTitles);
