import {LoaderStrategyInterface} from "~/services/PDF/P7SLoader/LoaderStrategyInterface";
import {AbstractLoaderStrategy} from "~/services/PDF/P7SLoader/AbstractLoaderStrategy";
import {TenderType} from "~/types/Tender/TenderType";
import {P7SLoadResultType} from "~/types/pdf/P7SLoadResultType";
import {messages} from "~/config/messages";
import {Assert} from "~/utils/Assert";
import {excludeFalsy} from "~/utils/helpers";
import {REGEX} from "~/constants/regex";
import {ENCODING} from "~/constants/encoding";
import {PdfDocumentConfigType} from "~/types/pdf/PdfDocumentConfigType";
import {DocumentType} from "~/types/Tender/DocumentType";
import {PdfTemplateTypes} from "~/services/PDF/PdfTemplateTypes";

export class TicketLoader extends AbstractLoaderStrategy implements LoaderStrategyInterface {
    public async load(object: TenderType, config: PdfDocumentConfigType): Promise<P7SLoadResultType> {
        const url = this.getDocumentUrl(object, config);
        const file = await this.getData(url);

        return {
            file,
            type: this.getDocumentType(config.title),
            encoding: ENCODING.WINDOWS_1251,
            url,
        };
    }

    private getDocumentUrl(object: TenderType, {date, title}: PdfDocumentConfigType): string {
        Assert.isDefined(object.awards, messages.error.undefinedAwards);

        const regexp = new RegExp(title);
        const documents = object.awards
            .map(award => award.documents)
            .filter(excludeFalsy)
            .flat()
            .filter((doc: DocumentType) => this.checkDateModified(doc.dateModified, date));

        const document = documents.find(doc => regexp.test(doc.title));

        Assert.isDefined(document, messages.error.undefinedDocumentTitle);

        return document.url;
    }

    private getDocumentType(documentTitle: string): PdfTemplateTypes {
        const result = REGEX.FILE.TYPE.INVOICE.exec(documentTitle);

        Assert.isDefined(result, messages.error.wrongDocumentType);
        Assert.isDefined(result.groups, messages.error.wrongDocumentType);

        return result.groups.type as PdfTemplateTypes;
    }
}
