export const medicineV2titlesConfig: Record<string, string> = {
    subject: "ПРЕДМЕТ ДОГОВОРУ", // 1
    assortment: "АСОРТИМЕНТ ТА КІЛЬКІСТЬ ТОВАРУ", // 2
    guarantee: "ЯКІСТЬ ТОВАРУ", // 3
    price: "ЦІНА ДОГОВОРУ ТА ЦІНА ТОВАРУ", // 4
    paymentOrder: "ПОРЯДОК РОЗРАХУНКІВ", // 5
    package: "ЗАСОБИ УПАКОВКИ, МАРКУВАННЯ ТА ФАСУВАННЯ", // 6
    deliveryTerms: "ПОРЯДОК ПОСТАВКИ, ПРИЙМАННЯ-ПЕРЕДАЧА ТОВАРУ", // 7
    responsibilities: "ВІДПОВІДАЛЬНІСТЬ СТОРІН", // 8
    forceMajeure: "ОБСТАВИНИ НЕПЕРЕБОРНОЇ СИЛИ (ФОРС-МАЖОР)", // 9
    disputes: "ВИРІШЕННЯ СПОРІВ", // 10
    anticorruption: "АНТИКОРУПЦІЙНЕ ЗАСТЕРЕЖЕННЯ", // 11
    sanction: "САНКЦІЙНІ ЗАСТЕРЕЖЕННЯ", // 12
    contractAction: "СТРОК ДОГОВОРУ", // 13
    messaging: "ВНЕСЕННЯ ЗМІН ДО ДОГОВОРУ ТА УМОВИ РОЗІРВАННЯ ДОГОВОРУ", // 14
    confidential: "КОНФІДЕНЦІЙНА ІНФОРМАЦІЯ", // 15
    otherTerms: "ІНШІ УМОВИ", // 16
};
