export const PROTOCOL_ON_EXTENSION_OF_REVIEW_PERIOD: Record<string, string> = {
    title: "ПРОТОКОЛ/ПОВІДОМЛЕННЯ",
    subtitle: "продовження строку розгляду тендерної пропозиції/пропозиції",
    customer_info: "Найменування замовника/організатора закупівлі:",
    customer_category: "Категорія замовника/організатора закупівлі:",
    customer_edrpou: "Ідентифікаційний код замовника/організатора закупівлі в ЄДР:",
    customer_location: "Місцезнаходження замовника/організатора закупівлі:",
    type_of_purchase: "Вид закупівлі:",
    procuring_entity_title: "Назва предмета закупівлі:",
    has_been_resolved: "Вирішено:",
    has_been_resolved_text: "Затвердити рішення про продовження строку розгляду тендерної пропозиції/пропозиції та опублікувати на веб-порталі Уповноваженого органу.",
    grounds_for_extension_tender: "Підстави для продовження строку розгляду тендерної пропозиції/пропозиції учасника:",
    participants_name_table: "Найменування учасника (для юридичної особи) або прізвище, ім’я, по батькові (для фізичної особи) щодо якого приймається рішення",
    awards_value_amount_table: "Ціна тендерної пропозиції учасника",
    awards_weighted_value_table: "Приведена ціна тендерної пропозиції учасника",
    awards_amount_performance_value_table: "Показник ефективності енергосервісного договору",
    with_tax: "з ПДВ",
};
