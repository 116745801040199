import {TypeCheckerInterface} from "~/utils/checker/TypeCheckerInterface";
import {EmptyCheckerInterface} from "~/utils/checker/EmptyCheckerInterface";
import {TypeHandlerInterface} from "~/services/DataTypeValidator/TypeHandlerInterface";

export abstract class AbstractTypeCheckHandler implements TypeHandlerInterface {
    protected constructor(
        protected readonly typeChecker: TypeCheckerInterface,
        protected readonly emptyChecker: EmptyCheckerInterface,
    ) {}

    abstract validate(data: any): boolean;
}
