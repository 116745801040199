import {PQContractType} from "~/widgets/pq/types/PQTypes";
import {TemplateCodesEnum} from "~/widgets/pq/types/TemplateCodes.enum";
import {IPQBuilder} from "~/widgets/pq/PQBuilderInterface";
import {TemplateBuildHelper} from "~/widgets/pq/templates/TemplateBuildHelper";

export class PharmBuilder implements IPQBuilder {
    build(contractObject: PQContractType | Record<string, never>, contractTemplate: TemplateCodesEnum, _tender: any): Record<string, any>[] {
        return TemplateBuildHelper.pharmBuild(contractObject, contractTemplate, _tender);
    }
}
