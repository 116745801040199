import * as PDF_HELPER_CONST from "~/constants/pdf/pdfHelperConstants";

export const HEADING_MARGIN = [
    0,
    PDF_HELPER_CONST.MARGIN_20,
    0,
    PDF_HELPER_CONST.MARGIN_10,
];

export const PQ_LIST_HEADING_MARGIN = [
    0,
    PDF_HELPER_CONST.MARGIN_15,
    0,
    0,
];

export const PQ_TESTING_GROUNDS_MARGIN = [
    0,
    0,
    0,
    PDF_HELPER_CONST.MARGIN_15,
];

export const PQ_SPECIFICATION_HEADING_MARGIN = [
    0,
    PDF_HELPER_CONST.MARGIN_15,
    0,
    PDF_HELPER_CONST.MARGIN_20,
];
