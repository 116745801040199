import {AxiosStatic} from "axios";
import {Assert} from "~/widgets/ErrorExceptionCore/Assert";
import {LoaderStrategyInterface} from "~/services/PDF/P7SLoader/LoaderStrategyInterface";
import {LoaderFactoryInterface} from "~/services/PDF/P7SLoader/LoaderFactoryInterface";
import {Base64Interface} from "~/utils/Base64Interface";
import {ERROR_MESSAGES} from "~/widgets/ErrorExceptionCore/configs/messages";
import {ERROR_CODES} from "~/widgets/ErrorExceptionCore/constants/ERROR_CODES.enum";

export class LoaderFactory implements LoaderFactoryInterface {
    constructor(
        private readonly typesMap: Map<string, new(base64: Base64Interface, axios: AxiosStatic) => LoaderStrategyInterface>,
        private readonly base64: Base64Interface,
        private readonly axios: AxiosStatic,
    ) {}

    public create(type: string): LoaderStrategyInterface {
        const strategyClass = this.typesMap.get(type);

        Assert.isDefined(strategyClass, ERROR_MESSAGES.SERVICE_UNAVAILABLE.loaderTypeIsNotDefined, ERROR_CODES.SERVICE_UNAVAILABLE);

        return new strategyClass(this.base64, this.axios);
    }
}
