import {XMLParserInterface} from "~/services/Dom/XMLParserInterface";
import {DocumentFactoryInterface} from "~/services/PDF/document/DocumentFactoryInterface";
import {DocumentStrategyInterface} from "~/services/PDF/document/DocumentStrategyInterface";
import {Assert} from "~/utils/Assert";

export class DocumentFactory implements DocumentFactoryInterface {
    constructor(
        private readonly typesMap: Map<string, new(xmlParser: XMLParserInterface) => DocumentStrategyInterface>,
        private readonly xmlParser: XMLParserInterface,
    ) {}

    public create(type: string): DocumentStrategyInterface {
        const strategyClass = this.typesMap.get(type);

        Assert.isDefined(strategyClass, "Cannot get document type strategy");

        return new strategyClass(this.xmlParser);
    }
}
