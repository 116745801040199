export const CONCLUSION_OF_MONITORING_TEXTS_LIST: Record<string, string> = {
    title: "Висновок",
    title_introduction: "І. Вступна частина",
    title_concluding_part: "ІІ. Констатуюча частина",
    underline_spaces: ".........................................................", // 56 spaces
    approve: "ЗАТВЕРДЖУЮ",
    position: "(посада керівника (заступника керівника) органу державного фінансового контролю)",
    position_2: "(посада)",
    sign: "(підпис)",
    sign_full_name_old: "(підпис, власне ім'я, прізвище)",
    sign_full_name_new: "(підпис Власне ім'я ПРІЗВИЩЕ)",
    full_name_old: "(власне ім'я, прізвище)",
    full_name_new: "(Власне ім'я ПРІЗВИЩЕ)",
    date: "(дата)",
    result_text: "про результати моніторингу процедури закупівлі",
    customer_info_new: "1. Інформація про замовника: повне найменування замовника, щодо якого здійснювався моніторинг процедури закупівлі; ідентифікаційний код замовника в Єдиному державному реєстрі юридичних осіб, фізичних осіб - підприємців та громадських формувань; місцезнаходження:",
    info_about_subject_new: "2. Інформація про предмет закупівлі: назва предмета закупівлі із зазначенням коду за Єдиним закупівельним словником (у разі поділу на лоти такі відомості мають зазначатися щодо кожного лота) та назви відповідних класифікаторів предмета закупівлі і частин предмета закупівлі (лотів) (за наявності), а також його очікувана вартість:",
    customer_info_old: "1. Інформація про замовника: повне найменування замовника, щодо якого здійснювався моніторинг процедури закупівлі; ідентифікаційний код юридичної особи в Єдиному державному реєстрі юридичних осіб, фізичних осіб - підприємців та громадських формувань; місцезнаходження:",
    info_about_subject_old: "2. Інформація про предмет закупівлі: назва предмета закупівлі із зазначенням коду за Єдиним закупівельним словником(у разі поділу на лоти такі відомості мають зазначатися щодо кожного лота) та назви відповідних класифікаторів предмета закупівлі і частин предмета закупівлі (лотів) (за наявності), а також його очікувана вартість:",
    info_about_disclosure: "3. Інформація про оприлюднення:",
    procurement_procedure: "4. Застосована процедура закупівлі:",
    grounds_for_monitoring: "5. Підстава здійснення моніторингу:",
    monitoring_start_date: "6. Дата початку моніторингу:",
    monitoring_end_date: "1. Дата закінчення моніторингу процедури закупівлі та інформація про результати моніторингу процедури закупівлі в розрізі стадій проведення процедури закупівлі:",
    conclusion_on_presence: "2. Висновок про наявність (із зазначенням переліку статей, пунктів нормативно-правових актів, що були порушені) або відсутність порушення, (порушень) законодавства:",
    commitment: "3. Зобов'язання щодо усунення порушення (порушень) законодавства у сфері публічних закупівель (у разі наявності таких порушень):",
    article_351: "Відповідно до статті 351¹ Кримінального кодексу України невиконання службовою особою законних вимог органу державного фінансового контролю, створення штучних перешкод у його роботі, надання йому завідомо неправдивої інформації караються штрафом від ста до однієї тисячі неоподатковуваних мінімумів доходів громадян або арештом на строк до шести місяців, або обмеженням волі на строк до трьох років.\n",
};
