import * as PDF_HELPER_CONST from "~/constants/pdf/pdfHelperConstants";

export const NAZK_PAGE_MARGIN = [
    PDF_HELPER_CONST.MARGIN_40,
    PDF_HELPER_CONST.MARGIN_40,
    PDF_HELPER_CONST.MARGIN_40,
    PDF_HELPER_CONST.MARGIN_120,
];

export const TABLE_COLUMN_RIGHT_MARGIN = [
    PDF_HELPER_CONST.MARGIN_10,
    PDF_HELPER_CONST.MARGIN_40,
    0,
    PDF_HELPER_CONST.MARGIN_5,
];
