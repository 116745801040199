import {LoaderStrategyInterface} from "~/services/PDF/P7SLoader/LoaderStrategyInterface";
import {Base64Interface} from "~/utils/Base64Interface";
import {P7SLoadResultType} from "~/types/pdf/P7SLoadResultType";
import {PdfDocumentConfigType} from "~/types/pdf/PdfDocumentConfigType";
import {HTTP} from "~/constants/http";
import {PdfError} from "~/services/Error/PdfError";
import {messages} from "~/config/messages";
import {PdfObjectType} from "~/types/pdf/PdfObjectType";
import {AxiosResponse, AxiosStatic} from "axios";

export abstract class AbstractLoaderStrategy implements LoaderStrategyInterface {
    constructor(
        protected readonly base64: Base64Interface,
        private readonly axios: AxiosStatic,
    ) {}

    protected async getData(url: string): Promise<string> {
        try {
            const {status, data}: AxiosResponse = await this.axios.get(url, {
                responseType: "blob",
            });

            if (status !== HTTP.STATUS.OK) {
                throw new PdfError(messages.error.documentAccess);
            }

            return await this.base64.encode(data);
        } catch {
            throw new PdfError(messages.error.documentAccess);
        }
    }

    protected checkDateModified(dateModified?: string, documentDate?: string): boolean {
        return documentDate && dateModified ? documentDate === dateModified : true;
    }

    protected approximateCheckDateModified(dateModified?: string, documentDate?: string): boolean {
        return documentDate && dateModified ? dateModified.slice(0, documentDate.length) === documentDate : true;
    }

    abstract load(object: PdfObjectType, config: PdfDocumentConfigType): Promise<P7SLoadResultType>;
}
