import {AbstractLoaderStrategy} from "~/services/PDF/P7SLoader/AbstractLoaderStrategy";
import {LoaderStrategyInterface} from "~/services/PDF/P7SLoader/LoaderStrategyInterface";
import {PdfDocumentConfigType} from "~/types/pdf/PdfDocumentConfigType";
import {PdfTemplateTypes} from "~/services/PDF/PdfTemplateTypes";
import {ENCODING} from "~/constants/encoding";
import {DocumentType} from "~/types/Tender/DocumentType";
import {P7SLoadResultType} from "~/types/pdf/P7SLoadResultType";
import {Assert} from "~/widgets/ErrorExceptionCore/Assert";
import {ERROR_MESSAGES} from "~/widgets/ErrorExceptionCore/configs/messages";
import {ArrayHandler} from "~/utils/ArrayHandler";
import {SIGNATURE_FILE_NAME} from "~/constants/string";

export class AnnualProcurementPlan extends AbstractLoaderStrategy implements LoaderStrategyInterface {
    public async load({documents}: any, config: PdfDocumentConfigType): Promise<P7SLoadResultType> {
        Assert.isDefined(documents, ERROR_MESSAGES.VALIDATION_FAILED.documentListUndefined);

        const url = this.getDocumentUrl(documents, config);
        const file = await this.getData(url);
        return {
            file,
            type: PdfTemplateTypes.ANNUAL_PROCUREMENT_PLAN,
            encoding: config.encoding || ENCODING.UTF_16,
            url,
        };
    }

    private getDocumentUrl(documentList: Array<DocumentType>, {date}: PdfDocumentConfigType): string {
        const documents = documentList.filter((doc: Record<string, any>) => doc.title === SIGNATURE_FILE_NAME && this.approximateCheckDateModified(doc.dateModified, date));
        const document = ArrayHandler.getLastElement(documents);

        Assert.isDefined(document, ERROR_MESSAGES.VALIDATION_FAILED.undefinedDocumentTitle);

        return document.url;
    }
}
