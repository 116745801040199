export const MILESTONE_EVENT: Record<string, string> = {
    "executionOfWorks": "Виконання робіт",
    "deliveryOfGoods": "Поставка товару",
    "submittingServices": "Надання послуг",
    "signingTheContract": "Підписання договору",
    "submissionDateOfApplications": "Дата подання заявки",
    "dateOfInvoicing": "Дата виставлення рахунку",
    "endDateOfTheReportingPeriod": "Дата закінчення звітного періоду",
    "anotherEvent": "Iнша подія ",
};
