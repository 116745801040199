export const softwareTitlesConfig: Record<string, string> = {
    terms: "ТЕРМІНИ ТА ВИЗНАЧЕННЯ", // 1
    subject: "ПРЕДМЕТ ДОГОВОРУ", // 2
    assortment: "АСОРТИМЕНТ ТА КІЛЬКІСТЬ ПРОГРАМНОГО ЗАБЕЗПЕЧЕННЯ", // 3
    guarantee: "ЯКІСТЬ ПРОГРАМНОГО ЗАБЕЗПЕЧЕННЯ", // 4
    price: "ЦІНА ДОГОВОРУ ТА ЦІНА ПРОГРАМНОГО ЗАБЕЗПЕЧЕННЯ", // 5
    paymentOrder: "ПОРЯДОК РОЗРАХУНКІВ", // 6
    deliveryTerms: "ПОРЯДОК ПРИЙМАННЯ-ПЕРЕДАЧІ ПРОГРАМНОГО ЗАБЕЗПЕЧЕННЯ", // 7
    intellectualProperty: "ПРАВА ІНТЕЛЕКТУАЛЬНОЇ ВЛАСНОСТІ", // 8
    technicalSupport: "ЗМІСТ ТА ПОРЯДОК НАДАННЯ ПОСЛУГ З ТЕХНІЧНОЇ ПІДТРИМКИ", // 9
    responsibilities: "ВІДПОВІДАЛЬНІСТЬ СТОРІН", // 10
    forceMajeure: "ОБСТАВИНИ НЕПЕРЕБОРНОЇ СИЛИ (ФОРС-МАЖОР)", // 11
    disputes: "ВИРІШЕННЯ СПОРІВ", // 12
    anticorruption: "АНТИКОРУПЦІЙНЕ ЗАСТЕРЕЖЕННЯ", // 13
    sanction: "САНКЦІЙНІ ЗАСТЕРЕЖЕННЯ", // 14
    contractAction: "СТРОК ДОГОВОРУ", // 15
    messaging: "ВНЕСЕННЯ ЗМІН ДО ДОГОВОРУ ТА УМОВИ РОЗІРВАННЯ ДОГОВОРУ", // 16
    confidential: "КОНФІДЕНЦІЙНА ІНФОРМАЦІЯ", // 17
    otherTerms: "ІНШІ УМОВИ", // 18
};
