import {DocumentExtractionService} from "~/services/PDF/document/DocumentExtractionService";
import {CriterionValues} from "~/constants/tender/criterion.enum";
import {STRING} from "~/constants/string";
import {StringConversionHelper} from "~/services/Common/StringConversionHelper";
import {ANNOUNCEMENT_TEXTS_LIST} from "~/config/pdf/texts/ANNOUNCEMENT";
import {PDFTablesHandler} from "~/services/PDF/Formatting/PDFTablesHandler";
import get from "lodash.get";
import {CriterionType, RequirementType} from "~/types/TenderOffer/Tender";
import {PDF_FILED_KEYS} from "~/constants/pdf/pdfFieldKeys";

export class CriteriaHandler {
    static getCriterionLanguage(criteria: CriterionType[]): Record<string, any> {
        const formattedCriterion = CriteriaHandler.criterionValuesFormatter(criteria, CriterionValues.BID_LANGUAGE);
        return formattedCriterion.length ? formattedCriterion : [];
    }

    // виправити відображення критерія забезпечення пропозиції - вимога може бути архівною і її не треба відображати
    // Відображати всі активні вимоги (тільки requirements зі status==active).
    // Відображати в окремій таблиці зі стандартним заголовком.
    static getCriterionSecuringOffer(criteria: CriterionType[]): Record<string, any>[] {
        const formattedCriterion = this.criterionValuesFormatter(criteria, CriterionValues.BID_GUARANTEE);
        if (!formattedCriterion.length) {
            return [];
        }

        return [
            {
                text: ANNOUNCEMENT_TEXTS_LIST.securement_terms,
                style: PDF_FILED_KEYS.HEADING_TITLE,
            },
            formattedCriterion,
        ];
    }

    static getCriterionSecurityContract(criteria: CriterionType[]): Record<string, any>[] {
        const values = this.criterionContractValuesFormatter(criteria, CriterionValues.CONTRACT_GUARANTEE);
        return values.length ? [
            {
                text: ANNOUNCEMENT_TEXTS_LIST.criterion_security_contract,
                style: PDF_FILED_KEYS.HEADING_TITLE,
            },
            ...this.criterionContractValuesFormatter(criteria, CriterionValues.CONTRACT_GUARANTEE),
        ] : [];
    }

    static criterionValuesFormatter(criteria: CriterionType[], criterionValue: CriterionValues): Record<string, any>[] {
        const filtered_criteria = this.getFilteredCriteria(criteria, criterionValue);
        const criterionResult:Record<string, any>[] = [];
        if (!Array.isArray(criteria) || !criteria.length || !filtered_criteria.length) {
            return criterionResult;
        }

        const requirements = DocumentExtractionService.getField<RequirementType[]>(filtered_criteria, "[0].requirementGroups[0].requirements");
        requirements.map(currentRequirement => {
            if (currentRequirement.status === "active") {
                criterionResult.push(
                    PDFTablesHandler.showIfAvailable(
                        {
                            value: `${this.getRequirementValue(currentRequirement)} ${currentRequirement.unit?.name ?? STRING.EMPTY}`,
                            title: `${DocumentExtractionService.getField<string>(currentRequirement, "title")}:`,
                        },
                    ),
                );
            }
        });
        return criterionResult;
    }

    static getFilteredCriteria(criteria: CriterionType[], criterionValue: CriterionValues): CriterionType[] {
        return criteria?.filter(item => {
            const criterionValueConditional = DocumentExtractionService.getField(item, "classification.id") === criterionValue;
            const activeRequirements = DocumentExtractionService.getField<RequirementType[]>(item, "requirementGroups[0].requirements")
                .filter(requirement => requirement.status === "active").length;
            return criterionValueConditional && Boolean(activeRequirements);
        }) ?? [];
    }

    static getRequirementValue(requirement: RequirementType): string {
        let value: number | boolean | string | string[] = get(requirement, "expectedValue") || STRING.EMPTY;
        if (typeof value === "number") {
            return value.toString();
        }

        if (typeof value === "boolean") {
            value = StringConversionHelper.yesNoStringConversion(value);
        } else {
            value = get(requirement, "expectedValues") || [];
            value = (value as string[]).join(" \n ");
        }
        return value;
    }

    /*
    *     Виводити з критерія в якому tender.criteria.classification.id===CRITERION.OTHER.CONTRACT.GUARANTEE назву групи зверху з tender.criteria.requirementGroups.description, потім назву поля зліва з tender.criteria.requirementGroups.requirements.title: і виводити значення поля справа з tender.criteria.requirementGroups.requirements.expectedValue (true/True - відображаємо “Так“) або tender.criteria.requirementGroups.requirements.expectedValues (масив, виводити всі значення з нового рядка).
    Додатково до значення відображати tender.criteria.requirementGroups.requirements.unit.name за наявності.

    Відображати всі активні вимоги (тільки requirements зі status==active).
    Відображати в окремій таблиці зі стандартним заголовком.
    Якщо в групі немає жодного активного реквайремента, то не виводити назву групи.
    Якщо такого критерія немає чи в критерії немає жодного активного реквайремента, то не виводити таблицю з заголовком.
    * */

    static criterionContractValuesFormatter(criteria: CriterionType[], criterionValue: CriterionValues): Record<string, any>[] {
        const filtered_criteria = this.getFilteredCriteria(criteria, criterionValue);

        if (!Array.isArray(criteria) || !criteria.length || !filtered_criteria.length) {
            return [];
        }

        const requirements = DocumentExtractionService.getField<RequirementType[]>(filtered_criteria, "[0].requirementGroups[0].requirements");
        const description = DocumentExtractionService.getField<string>(filtered_criteria, "[0].requirementGroups[0].description");
        const criterionResult = [];

        if (description) {
            criterionResult.push(PDFTablesHandler.showIfAvailable(
                {
                    value: STRING.WHITESPACE,
                    title: description,
                }));
        }

        requirements.map(currentRequirement => {
            if (currentRequirement.status === "active") {
                criterionResult.push(
                    PDFTablesHandler.showIfAvailable(
                        {
                            value: `${this.getRequirementValue(currentRequirement)} ${currentRequirement.unit?.name ?? STRING.EMPTY}`,
                            title: `${DocumentExtractionService.getField<string>(currentRequirement, "title")}:`,
                        },
                    ),
                );
            }
        });
        return criterionResult;
    }
}
