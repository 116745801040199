import {TemplateCodesEnum} from "~/types/PQ/TemplateCodes.enum";
import {PQspecificationListItem} from "~/types/PQ/PQTypes";
import {generalListConfig} from "~/config/pdf/pq/underTableListConfigs/generalListConfig";
import {fuelListConfig} from "~/config/pdf/pq/underTableListConfigs/fuelListConfig";
import {medicineListConfig} from "~/config/pdf/pq/underTableListConfigs/medicineListConfig";

export const TemplateToSpecificationListMap = new Map<string, PQspecificationListItem[][]>()
    .set(TemplateCodesEnum.FRUIT, generalListConfig)
    .set(TemplateCodesEnum.COMPUTER, generalListConfig)
    .set(TemplateCodesEnum.OTHER, generalListConfig)
    .set(TemplateCodesEnum.MEDICINE, medicineListConfig)
    .set(TemplateCodesEnum.PHARM, medicineListConfig)
    .set(TemplateCodesEnum.GAS, fuelListConfig);
