export const COMPLAINT_TEXTS_LISTS: Record<string, string> = {
    organisation: "АНТИМОНОПОЛЬНИЙ КОМІТЕТ УКРАЇНИ",
    organisation_address: "03680, м. Київ, вул. Митрополита В. Липківського, 45",
    author_identifier: "Ім’я (найменування) суб’єкта оскарження",
    author_scheme: "ЄДРПОУ суб’єкта оскарження",
    author_address: "Місце проживання (місцезнаходження) суб’єкта оскарження",
    identifier_legalName: "Найменування замовника рішення, дії або бездіяльність якого оскаржуються",
    identifier_id: "ЄДРПОУ замовника",
    tender_id: "Ідентифікатор закупівлі",
    complaint_id: "Ідентифікатор скарги",
    objection_title: "1. Заголовок пункту скарги",
    objections_classification: "2. Вимога законодавства про публічні закупівлі, з яким пов’язане вчинене замовником порушення",
    objection_description: "3. Опис пункту скарги, а саме:",
    objection_argument: "Підстава подання скарги та посилання на порушення процедури закупівлі або прийняті рішення, дії або бездіяльність замовника, фактичні обставини, що це можуть підтверджувати;",
    objection_evidence: "Обґрунтування наявності у суб’єкта оскарження порушених прав та охоронюваних законом інтересів з приводу рішення, дії чи бездіяльності замовника, що суперечать законодавству у сфері публічних закупівель і внаслідок яких порушено право чи законні інтереси такої особи",
    requested_remedies: "4. Вимога суб’єкта оскарження",
    document_list: "Перелік документів:",
};
