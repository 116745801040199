import {LoaderStrategyInterface} from "~/services/PDF/P7SLoader/LoaderStrategyInterface";
import {AbstractLoaderStrategy} from "~/services/PDF/P7SLoader/AbstractLoaderStrategy";
import {P7SLoadResultType} from "~/types/pdf/P7SLoadResultType";
import {messages} from "~/config/messages";
import {Assert} from "~/utils/Assert";
import {ENCODING} from "~/constants/encoding";
import {PdfDocumentConfigType} from "~/types/pdf/PdfDocumentConfigType";
import {PdfTemplateTypes} from "~/services/PDF/PdfTemplateTypes";
import {ArrayHandler} from "~/utils/ArrayHandler";
import {BidType} from "~/types/TenderOffer/Tender";

export class TenderOfferLoader extends AbstractLoaderStrategy implements LoaderStrategyInterface {
    public async load(object: BidType, config: PdfDocumentConfigType): Promise<P7SLoadResultType> {
        const {documents, financialDocuments} = object;
        const allDocuments = [...documents ?? [], ...financialDocuments ?? []];
        if (allDocuments.length === 0) {
            throw new Error(messages.error.documentListUndefined);
        }
        const url = this.getDocumentUrl(allDocuments, config);
        const file = await this.getData(url);
        return {
            file,
            type: PdfTemplateTypes.TENDER_OFFER_TEMPLATE,
            encoding: config.encoding || ENCODING.UTF_16,
            url,
        };
    }

    private getDocumentUrl(documents: Record<string, any>, {date}: PdfDocumentConfigType): string {
        Assert.isDefined(documents, messages.error.documentListUndefined);
        const list = documents.filter((doc: Record<string, string>) => doc.documentType === "proposal" && this.approximateCheckDateModified(doc.dateModified, date));
        const document: Record<string, any> | undefined = ArrayHandler.getLastElement(list);
        Assert.isDefined(document, messages.error.undefinedDocumentTitle);

        return document.url as string;
    }
}
