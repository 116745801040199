import {DEFAULT_TEXT_FIELDS} from "~/constants/string";

export const XML_TEXTS_LIST: Record<string, string> = Object.assign({
    title: "Відповідь на запит",
    subtitle: "щодо надання інформації про відсутність або наявність заборгованості (податкового боргу) із сплати податків, зборів, платежів, контроль за якими покладено на органи Державної фіскальної служби, в учасника процедури закупівлі",
    group1: "Відомості про адміністратора,",
    group2: "який зареєстрований в",
    group3: "Учасник процедури закупівлі",
    HNAME: "Найменування",
    HTIN: "Код згідно з ЄДРПОУ",
    HKSTI_HSTI: "Код та назва територіального органу ДФС",
    R0201G1S: "Код згідно з ЄДРПОУ / реєстраційний номер облікової картки платника податків або серія (за наявності) та номер паспорта",
    R0202G1S: "Найменування",
    R0203G1S: "Прізвище",
    R0204G1S: "Ім’я",
    R0201G1S_2: "По батькові (за наявності)",
    R0301G1S: "Результат обробки запиту",
    R0401G1S: "Позначка про відсутність / наявність заборгованості",
    HFILL_HTIME: "Дата та час формування відповіді",
}, DEFAULT_TEXT_FIELDS);

export const XML_RESULT_TESTS_LIST: Record<string, Record<string, string>> = {
    R0301G1S: {
        "": "",
        "0": "Надано інформацію про відсутність / наявність заборгованості",
        "1": "Учасник процедури закупівлі за вказаним у запиті кодом згідно з ЄДРПОУ / реєстраційним номером облікової картки платника податків або серією (за наявності) та номером паспорта на обліку в органах ДФС не перебуває",
        "2": "Учасника процедури закупівлі знято з обліку у контролюючих органах",
        "3": "Учасник процедури закупівлі за вказаним у запиті кодом згідно з ЄДРПОУ не є юридичною особою",
    },
    R0401G1S: {
        "0": "Відсутня заборгованість",
        "1": "Наявна заборгованість",
    },
};
