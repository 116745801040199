import {AxiosStatic} from "axios";
import {LoaderFactory} from "~/services/PDF/P7SLoader/LoaderFactory";
import {LoaderManagerInterface} from "~/services/PDF/P7SLoader/LoaderManagerInterface";
import {loaderStrategyMap} from "~/services/PDF/P7SLoader/LoaderStrategyMap";
import {PdfTypes} from "~/services/PDF/PdfTypes";
import {P7SLoadResultType} from "~/types/pdf/P7SLoadResultType";
import {PdfDocumentConfigType} from "~/types/pdf/PdfDocumentConfigType";
import {PdfObjectType} from "~/types/pdf/PdfObjectType";
import {Base64Interface} from "~/utils/Base64Interface";

export class LoaderManager implements LoaderManagerInterface {
    private readonly loaderFactory = new LoaderFactory(loaderStrategyMap, this.base64, this.axios);
    private dataGenerator = this.loaderFactory.create(PdfTypes.TICKET);

    constructor(
        private readonly base64: Base64Interface,
        private readonly axios: AxiosStatic,
    ) {}

    setLoaderType(type: string): void {
        this.dataGenerator = this.loaderFactory.create(type);
    }

    async getData(object: PdfObjectType, config: PdfDocumentConfigType): Promise<P7SLoadResultType> {
        return this.dataGenerator.load(object, config);
    }
}
