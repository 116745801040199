import {DEFAULT_TEXT_FIELDS, STRING} from "~/constants/string";
import {PdfItemEnum, TextConfigType} from "~/widgets/pq/types/TextConfigType";
import {pqGenericBase, pqGenericTexts} from "~/widgets/pq/templates/generic/configs/pqGenericTexts";
import {pqBase} from "~/widgets/pq/configs/pqTexts";
import {FormattingFunctionsEnum} from "~/widgets/pq/services/Formating/config/FormattingFunctions.enum";
import {foodTextsConfig} from "~/widgets/pq/templates/food/configs/foodTexts.config";

export const foodContractConfig: TextConfigType = {
    subject: [
        {
            text: [foodTextsConfig.subject.obligationsToBuyer, foodTextsConfig.subject.productClassificationID, 0],
            paths: ["items[0].classification.id"],
            defaults: [DEFAULT_TEXT_FIELDS.UNDERSCORES_16],
            pdfType: PdfItemEnum.LIST_ITEM,
        },
        foodTextsConfig.subject.ownership,
        foodTextsConfig.subject.terminology,
        foodTextsConfig.subject.guarantee,
        {
            text: [pqGenericTexts.subject.tender, 0, STRING.DELIMITER.DOT_NEW_LINE, pqGenericTexts.subject.note],
            paths: ["tenderID"],
            defaults: [DEFAULT_TEXT_FIELDS.UNDERSCORES_40],
            pdfType: PdfItemEnum.LIST_ITEM,
            functionName: [FormattingFunctionsEnum.TENDER_ID],
        },
    ],
    assortment: [
        foodTextsConfig.assortment.specification,
        foodTextsConfig.assortment.lowerSize,
    ],
    guarantee: [
        foodTextsConfig.guarantee.specification,
        foodTextsConfig.guarantee.securityMeasures,
        foodTextsConfig.guarantee.substandard,
        foodTextsConfig.guarantee.qualityControl,
        foodTextsConfig.guarantee.vetSpecialists,
        foodTextsConfig.guarantee.acceptanceQuality,
        foodTextsConfig.guarantee.increaseQuality,
        foodTextsConfig.guarantee.qualityPeriod,
        foodTextsConfig.guarantee.supportingDocuments,
        foodTextsConfig.guarantee.packing,
    ],
    price: [
        {
            text: [pqGenericTexts.price.amountDefinition, 0],
            paths: ["value.amount"],
            defaults: [DEFAULT_TEXT_FIELDS.DEFAULT_PRICE],
            pdfType: PdfItemEnum.LIST_ITEM,
            functionName: [FormattingFunctionsEnum.PRICE_WITH_TAX_TO_TEXT],
        },
        foodTextsConfig.price.unitPrice,
        foodTextsConfig.price.additionalIncluded,
    ],
    paymentOrder: [
        foodTextsConfig.paymentOrder.cashless,
        [
            pqGenericTexts.paymentOrder.buyerPays,
            {
                text: [0],
                paths: ["items"],
                defaults: [DEFAULT_TEXT_FIELDS.UNDERSCORES_16],
                pdfType: PdfItemEnum.LIST_ITEM,
                functionName: [FormattingFunctionsEnum.PAYMENT_DETAILS],
            },
        ],
        foodTextsConfig.paymentOrder.nonWorkingPayment,
        foodTextsConfig.paymentOrder.budgetPayment,
        [pqGenericTexts.paymentOrder.prepayment, pqGenericTexts.paymentOrder.tillDelivery, pqGenericTexts.paymentOrder.additionalAgreement, pqGenericTexts.paymentOrder.buyerRejection, pqGenericTexts.paymentOrder.supplierRejection],
        foodTextsConfig.paymentOrder.comercialCredit,
    ],
    package: [
        foodTextsConfig.package.prepacked,
        foodTextsConfig.package.hygienic,
        foodTextsConfig.package.unmarked,
        foodTextsConfig.package.meansOfPacking,
        foodTextsConfig.package.priceOfPacking,
        foodTextsConfig.package.packingOfBulk,
        foodTextsConfig.package.markingInfo,
    ],
    deliveryTerms: [
        foodTextsConfig.deliveryTerms.specification,
        foodTextsConfig.deliveryTerms.deliveryPayment,
        foodTextsConfig.deliveryTerms.hygienicTransport,
        foodTextsConfig.deliveryTerms.partiesDelivery,
        foodTextsConfig.deliveryTerms.dateOfDelivery,
        foodTextsConfig.deliveryTerms.receiveResponse,
        foodTextsConfig.deliveryTerms.deliveryPlace,
        foodTextsConfig.deliveryTerms.receiveResponseProcess,
        foodTextsConfig.deliveryTerms.propertyRights,
        foodTextsConfig.deliveryTerms.discoveredInconviniences,
        foodTextsConfig.deliveryTerms.overDelivery,
        foodTextsConfig.deliveryTerms.deliveryDocuments,
        foodTextsConfig.deliveryTerms.deliveryWithoutDocuments,
        foodTextsConfig.deliveryTerms.identifiedDeficienciesTime,
        foodTextsConfig.deliveryTerms.identifiedDeficienciesInformation,
        foodTextsConfig.deliveryTerms.disagreements,
        foodTextsConfig.deliveryTerms.requestOfNotDelivered,
        foodTextsConfig.deliveryTerms.assortment,
        foodTextsConfig.deliveryTerms.utilization,
        foodTextsConfig.deliveryTerms.relevantPowers,
    ],
    responsibilities: [
        foodTextsConfig.responsibilities.improperExecution,
        foodTextsConfig.responsibilities.violationOfDeliveryFee,
        foodTextsConfig.responsibilities.violationOfDeliveryDays,
        foodTextsConfig.responsibilities.agreementTermination,
        foodTextsConfig.responsibilities.poorQualityFees,
        foodTextsConfig.responsibilities.applicationOfFines,
        foodTextsConfig.responsibilities.taxCredit,
    ],
    forceMajeure: [
        foodTextsConfig.forceMajeure.onset,
        foodTextsConfig.forceMajeure.martialLaw,
        foodTextsConfig.forceMajeure.significantChanges,
        foodTextsConfig.forceMajeure.delayPeriod,
        foodTextsConfig.forceMajeure.mitigation,
        foodTextsConfig.forceMajeure.forceMajorMessage,
        foodTextsConfig.forceMajeure.sufficientEvidence,
        foodTextsConfig.forceMajeure.mutuallyAcceptableSolution,
        foodTextsConfig.forceMajeure.delayedExecution,
        foodTextsConfig.forceMajeure.circumstancesOfWar,
    ],
    disputes: [
        foodTextsConfig.disputes.parley,
        foodTextsConfig.disputes.court,
        foodTextsConfig.disputes.claim,
    ],
    anticorruption: [
        foodTextsConfig.anticorruption.undueAdvantages,
        foodTextsConfig.anticorruption.notificationOnUndueAdvantages,
        foodTextsConfig.anticorruption.violationOfRequirements,
        foodTextsConfig.anticorruption.dueDiligence,
        foodTextsConfig.anticorruption.fullConfidentiality,
        foodTextsConfig.anticorruption.civilCodex,
    ],
    sanction: [
        foodTextsConfig.sanction.unilateralRefusal,
        foodTextsConfig.sanction.customerRefusal,
        foodTextsConfig.sanction.origin,
        foodTextsConfig.sanction.legalEntity,
    ],
    contractAction: [
        {
            text: [pqGenericTexts.contractAction.agreementValidTermStart, 0, pqGenericTexts.contractAction.agreementValidTermEnd],
            paths: ["period.endDate"],
            defaults: [pqBase.year],
            pdfType: PdfItemEnum.LIST_ITEM,
            functionName: [FormattingFunctionsEnum.CONVERT_DATE],
        },
        foodTextsConfig.contractAction.expirationDate,
        foodTextsConfig.contractAction.canContinue,
    ],
    messaging: [
        foodTextsConfig.messaging.noDifferFromOffer,
        foodTextsConfig.messaging.essentialConditions,
        foodTextsConfig.messaging.changes,
        foodTextsConfig.messaging.partyProvidingChanges,
        foodTextsConfig.messaging.changePaymentDetails,
        foodTextsConfig.messaging.earlyTermination,
        foodTextsConfig.messaging.terminationSavesResponsibility,
        foodTextsConfig.messaging.reconciliation,
    ],
    confidential: [
        foodTextsConfig.confidential.confidentialInfo,
        foodTextsConfig.confidential.passingInfoForThirdParties,
        foodTextsConfig.confidential.notifyOnDisclosure,
        foodTextsConfig.confidential.noObjectReceiving,
    ],
    otherTerms: [
        foodTextsConfig.otherTerms.currentLegislation,
        foodTextsConfig.otherTerms.interpretedInAccordance,
        foodTextsConfig.otherTerms.warrantsToOtherParty,
        {
            text: [pqGenericTexts.otherTerms.messages, STRING.DELIMITER.NEW_LINE, pqGenericBase.fromSupplier, 0, STRING.DELIMITER.NEW_LINE, pqGenericBase.fromBuyer, 1, STRING.DELIMITER.NEW_LINE, pqGenericTexts.otherTerms.messagesDetailsInAgreement],
            paths: ["suppliers[0].signerInfo.email", "buyer.signerInfo.email"],
            defaults: [DEFAULT_TEXT_FIELDS.UNDERSCORES_40, DEFAULT_TEXT_FIELDS.UNDERSCORES_40],
            pdfType: PdfItemEnum.LIST_ITEM,
        },
        foodTextsConfig.otherTerms.recognisedDocuments,
        foodTextsConfig.otherTerms.originalCopies,
        foodTextsConfig.otherTerms.factoring,
        foodTextsConfig.otherTerms.transferRights,
        foodTextsConfig.otherTerms.integralPart,
    ],
};
