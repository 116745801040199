import {LoaderStrategyInterface} from "~/services/PDF/P7SLoader/LoaderStrategyInterface";
import {AbstractLoaderStrategy} from "~/services/PDF/P7SLoader/AbstractLoaderStrategy";
import {P7SLoadResultType} from "~/types/pdf/P7SLoadResultType";
import {ERROR_MESSAGES} from "~/widgets/ErrorExceptionCore/configs/messages";
import {Assert} from "~/widgets/ErrorExceptionCore/Assert";
import {ENCODING} from "~/constants/encoding";
import {PdfDocumentConfigType} from "~/types/pdf/PdfDocumentConfigType";
import {PdfTemplateTypes} from "~/services/PDF/PdfTemplateTypes";
import {ArrayHandler} from "~/utils/ArrayHandler";
import {DocumentType} from "~/types/Tender/DocumentType";
import {AwardStatus, AwardType} from "~/types/Tender/AwardType";
import {ErrorExceptionCore} from "~/widgets/ErrorExceptionCore/ErrorExceptionCore";
import {ERROR_CODES} from "~/widgets/ErrorExceptionCore/constants/ERROR_CODES.enum";

export class TenderRejectionProtocolLoader extends AbstractLoaderStrategy implements LoaderStrategyInterface {
    public async load(object: AwardType, config: PdfDocumentConfigType): Promise<P7SLoadResultType> {
        const {documents, status, eligible, qualified} = object;

        Assert.isDefined(status, ERROR_MESSAGES.VALIDATION_FAILED.undefinedStatus);
        Assert.isDefined(documents, ERROR_MESSAGES.VALIDATION_FAILED.documentListUndefined);

        if (![AwardStatus.UNSUCCESSFUL, AwardStatus.CANCELLED].includes(status)) {
            throw new ErrorExceptionCore({
                code: ERROR_CODES.VALIDATION_FAILED,
                message: ERROR_MESSAGES.VALIDATION_FAILED.awardStatusNotFind,
            });
        }

        if (status === AwardStatus.CANCELLED) {
            if (!(eligible === false || qualified === false)) {
                throw new ErrorExceptionCore({
                    code: ERROR_CODES.VALIDATION_FAILED,
                    message: ERROR_MESSAGES.VALIDATION_FAILED.wrongEligibleOrQualified,
                });
            }
        }
        const url = this.getDocumentUrl(documents, config);
        const file = await this.getData(url);
        return {
            file,
            type: PdfTemplateTypes.TENDER_REJECTION_PROTOCOL_TEMPLATE,
            encoding: config.encoding || ENCODING.UTF_16,
            url,
        };
    }

    private getDocumentUrl(documentList: DocumentType[], {date}: PdfDocumentConfigType): string {
        const documents = documentList.filter((doc: Record<string, any>) => doc.documentType === "notice" && this.approximateCheckDateModified(doc.dateModified, date));
        const document = ArrayHandler.getLastElement(documents);

        Assert.isDefined(document, ERROR_MESSAGES.VALIDATION_FAILED.undefinedDocumentTitle);

        return document.url;
    }
}
