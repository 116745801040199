import * as PDF_HELPER_CONST from "~/constants/pdf/pdfHelperConstants";
export const MARGIN_TOP_10__BOTTOM_15 = [
    PDF_HELPER_CONST.MARGIN_0,
    PDF_HELPER_CONST.MARGIN_10,
    PDF_HELPER_CONST.MARGIN_0,
    PDF_HELPER_CONST.MARGIN_15,
];
export const MARGIN_TOP_3 = [
    PDF_HELPER_CONST.MARGIN_0,
    PDF_HELPER_CONST.MARGIN_3,
    PDF_HELPER_CONST.MARGIN_0,
    PDF_HELPER_CONST.MARGIN_0,
];
