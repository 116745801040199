import {PDF_FILED_KEYS} from "~/constants/pdf/pdfFieldKeys";
import * as PDF_HELPER_CONST from "~/constants/pdf/pdfHelperConstants";
import {AbstractDocumentStrategy} from "~/services/PDF/document/AbstractDocumentStrategy";
import {SignerType} from "types/sign/SignerType";
import {
    labAnalysisTexts,
    pqBase,
    pqSignature,
    pqSpecificationTexts,
    testingGroundsTexts,
} from "~/config/pdf/pq/pqTexts";
import get from "lodash.get";
import {
    HEADING_MARGIN,
    PQ_LIST_HEADING_MARGIN,
    PQ_SPECIFICATION_HEADING_MARGIN,
    PQ_TESTING_GROUNDS_MARGIN,
} from "~/config/pdf/pq/margins";
import {
    DEFAULT_PAGE_MARGIN,
    TABLE_COLUMN_LEFT_MARGIN,
    TABLE_COLUMN_RIGHT_MARGIN,
} from "~/config/pdf/announcementConstants";
import {
    CompoundStringListItem, PQattribute,
    PQContractType, PQItem,
    PQspecificationListItem, PQsupplier, PQvalue,
    TextListItem,
} from "~/types/PQ/PQTypes";
import {AddressType} from "~/types/Tender/AddressType";
import {ClassificationType} from "~/types/Tender/ClassificationType";
import {PDF_STYLES} from "~/config/pdf/pdfStyles";
import {TemplateToTextMap} from "~/config/pdf/pq/TemplateToText.map";
import {
    CompoundTextType,
    OlConfigType,
    OlPdfType,
    PdfItemEnum,
    TextConfigType,
} from "~/types/PQ/TextConfigType";
import {STRING} from "~/constants/string";
import {TypeChecker} from "~/utils/checker/TypeChecker";
import {computerTextsConfig, generalTableHeader} from "~/config/pdf/pq/contractTextConfigs/computersTextsConfig";
import {TemplateToTableHead} from "~/config/pdf/pq/TableHead.map";
import {TemplateToPqTitlesMap} from "~/config/pdf/pq/TemplateToPqTitles.map";
import {generalListConfig} from "~/config/pdf/pq/underTableListConfigs/generalListConfig";
import {TemplateToSpecificationListMap} from "~/config/pdf/pq/TemplateToSpecificationList.map";
import {DateHandler} from "~/utils/DateHandler";
import {TemplateCodesEnum} from "~/types/PQ/TemplateCodes.enum";
import {pqGeneralTitles} from "~/config/pdf/pq/pqTitles";

export class PQDataMaker extends AbstractDocumentStrategy {
    private readonly _typeChecker = new TypeChecker();

    create(
        contractTemplateName: string,
        _signers?: SignerType[],
        _dictionaries?: Map<string, Record<string, any>>,
        contract?: PQContractType,
    ): Record<string, any>[] {
        const contractObject = contract || {};
        const contractTemplate = contract?.contractTemplateName || contractTemplateName || TemplateCodesEnum.OTHER;
        const templateTexts = TemplateToTextMap.get(contractTemplate) || computerTextsConfig;
        const contractText = [];

        contractText.push(this.createHeader(contractObject as PQContractType));
        contractText.push(this.createContractText(templateTexts, contractObject));
        contractText.push(this.getLocationTitle(contractObject));
        contractText.push(this.createSignature(contractObject));
        contractText.push(this.createItemTable(contractObject));
        contractText.push(this.createSignature(contractObject));

        if (this.isMedicineTemplate(contractTemplate as TemplateCodesEnum)) {
            contractText.push(this.createMedAddition(contractTemplate));
            contractText.push(this.createSignature(contractObject));
        }

        return contractText;
    }

    createFooter(): Record<string, any>[] {
        return [PDF_HELPER_CONST.EMPTY_FIELD];
    }

    getPageMargins(): number[] {
        return DEFAULT_PAGE_MARGIN;
    }

    getLocationTitle(contractObject: PQContractType | Record<string, never>): Record<string, any> {
        const titles = TemplateToPqTitlesMap.get(contractObject.contractTemplateName) || pqGeneralTitles;
        const index = Object.keys(titles).length;

        return {
            margin: PQ_LIST_HEADING_MARGIN,
            text: `${index + 1}. ${pqBase.location}`,
            style: PDF_FILED_KEYS.HEADING,
        };
    }

    private createHeader(contractObject: PQContractType): Record<string, any>[] {
        const firstRow = {
            layout: PDF_HELPER_CONST.TABLE_LAYOUT_NO_BORDERS,
            table: {
                widths: ["*", "*"],
                body: [
                    [
                        {
                            text: [
                                pqBase.city,
                                this.getField(contractObject, "buyer.address.locality"),
                                pqBase.ukraine,
                            ].join(" "),
                            margin: TABLE_COLUMN_LEFT_MARGIN,
                        },
                        {
                            text: DateHandler.prepareDateSigned(this.getField(contractObject, "dateSigned"), "року"),
                            margin: TABLE_COLUMN_RIGHT_MARGIN,
                            alignment: "right",
                        },
                    ],
                ],
            },
        };
        const nextNamedBuyer = this.isMedicineTemplate(contractObject.contractTemplateName) ? pqBase.nextNamedCustomer : pqBase.nextNamedBuyer;

        const firstPar = [
            this.getField(contractObject, "buyer.identifier.legalName", pqBase.customerName),
            STRING.DELIMITER.COMMA,
            this.getField(contractObject, "buyer.identifier.id", pqBase.edrpou),
            nextNamedBuyer,
            this.getField(contractObject, "buyer.signerInfo.name", pqBase.accreditedPerson),
            pqBase.basisOf,
            this.getField(contractObject, "buyer.signerInfo.authorizedBy", pqBase.accreditedDocument),
            pqBase.onOneSide,
            pqBase.newLine.concat(pqBase.newLine),
        ];

        const nextBuyer = this.isMedicineTemplate(contractObject.contractTemplateName) ? STRING.EMPTY : pqBase.nextBuyer;
        const contractPartRemover = 3;

        const secondPar = [
            this.getField(contractObject, "suppliers[0].identifier.legalName", pqBase.supplierName),
            STRING.DELIMITER.COMMA,
            this.getField(contractObject, "suppliers[0].identifier.id"),
            pqBase.nextSupplier,
            this.getField(contractObject, "suppliers[0].signerInfo.name"),
            pqBase.basisOf,
            this.getField(contractObject, "suppliers[0].signerInfo.authorizedBy"),
            pqBase.onOneSide,
            this.getField(contractObject, "buyer.identifier.legalName"),
            nextBuyer,
            this.getField(contractObject, "buyer.signerInfo.name"),
            pqBase.nextSides,
            this.getField(contractObject, "contractID", pqBase.tenderId).slice(0, this.getField<string>(contractObject, "contractID").length - contractPartRemover),
            pqBase.nextContract,
        ];

        return [
            {
                text: [
                    pqBase.contractNumber,
                    this.getField(contractObject, "contractID"),
                ],
                style: PDF_FILED_KEYS.HEADING_TITLE,
                margin: HEADING_MARGIN,
            },
            firstRow,
            {
                text: firstPar,
                style: PDF_FILED_KEYS.TABLE_DATA,
            },
            {
                text: secondPar,
                style: PDF_FILED_KEYS.TABLE_DATA,
            },
        ];
    }

    private createContractText(textsConfig: TextConfigType, contractObject: PQContractType | Record<string, never>): Record<string, any> [] {
        const listText: Record<string, any>[] = [];
        const titles = TemplateToPqTitlesMap.get(contractObject.contractTemplateName) || pqGeneralTitles;

        Object.keys(titles).map((item, listIndex) => {
            listText.push({
                text: listIndex + 1 + STRING.DELIMITER.DOT + get(titles, item),
                style: PDF_FILED_KEYS.HEADING,
                margin: PQ_LIST_HEADING_MARGIN,
            });

            let consecutiveNoMarkerText = 0;

            (get(textsConfig, item) as OlConfigType[]).forEach((olItem, itemIndex) => {
                if (this._typeChecker.isCompoundTextType(olItem)) {
                    if ((olItem as CompoundTextType).pdfType === PdfItemEnum.TEXT) {
                        listText.push(this.formatNoMarkerText(olItem as CompoundTextType, contractObject));
                        consecutiveNoMarkerText += 1;
                    }

                    if ((olItem as CompoundTextType).pdfType === PdfItemEnum.LIST_ITEM) {
                        listText.push(this.formatOlItem(this.formatNoMarkerText(olItem as CompoundTextType, contractObject).text, listIndex, itemIndex, 0));
                    }

                    return;
                }

                if (Array.isArray(olItem)) {
                    this.formatOlArray(olItem, listIndex, itemIndex).forEach(oli => listText.push(oli));
                    return;
                }

                listText.push(this.formatOlItem(olItem as string, listIndex, itemIndex, consecutiveNoMarkerText));
                consecutiveNoMarkerText = 0;
            });
        });

        return listText;
    }

    private createItemTable(contractObject: PQContractType | Record<any, any>): Record<string, any>[] {
        const {items = []} = contractObject;

        const specificationHeader = this.specificationHeader(contractObject, this.getField(contractObject, "dateSigned"));
        const tableHeader = TemplateToTableHead.get(contractObject.contractTemplateName) || generalTableHeader;

        const body: any[][] = [];
        body.push(tableHeader);

        if ((items as [])?.length) {
            (items as PQItem[]).forEach(item => body.push([
                this.getField(item, "description"),
                this.formatAttribute(item.attributes),
                this.getField(item, "quantity"),
                this.getField(item, "unit.name"),
                this.getPrice(this.getField(item, "unit.value")),
                this.getPrice(this.getField(item, "unit.value"), Number(this.getField(item, "quantity"))),
            ]));
        } else {
            body.push([{}, {}, {}, {}, {}, {}]);
        }

        body.push([
            {
                text: pqSpecificationTexts.totalPrice,
                colSpan: 5,
            },
            {},
            {},
            {},
            {},
            this.getField(contractObject, "value.amountNet"),
        ]);

        body.push([
            {
                text: pqSpecificationTexts.tax,
                colSpan: 5,
            },
            {},
            {},
            {},
            {},
            (Number(this.getField(contractObject, "value.amount")) - Number(this.getField(contractObject, "value.amountNet"))).toString(),
        ]);

        body.push([
            {
                text: pqSpecificationTexts.priceWithTax,
                colSpan: 5,
            },
            {},
            {},
            {},
            {},
            this.getField(contractObject, "value.amount"),
        ]);

        const underTableList = {
            ol: this.createSpecificationUnderTableList(contractObject),
            style: PDF_STYLES.bold,
            margin: PQ_LIST_HEADING_MARGIN,
        };

        let specificNote = PDF_HELPER_CONST.EMPTY_FIELD;
        if (this.isMedicineTemplate(contractObject.contractTemplateName)) {
            specificNote = {
                text: pqSpecificationTexts.note,
                margin: PQ_SPECIFICATION_HEADING_MARGIN,
            };
        }

        return [
            specificationHeader,
            {
                headerRows: 1,
                table: {
                    dontBreakRows: true,
                    widths: [
                        PDF_HELPER_CONST.ROW_WIDTH_100,
                        PDF_HELPER_CONST.ROW_WIDTH_100,
                        PDF_HELPER_CONST.ROW_WIDTH_70,
                        PDF_HELPER_CONST.ROW_WIDTH_70,
                        PDF_HELPER_CONST.ROW_WIDTH_70,
                        PDF_HELPER_CONST.ROW_WIDTH_70,
                    ],
                    heights: 40,
                    body,
                },
            },
            underTableList,
            specificNote,
        ];
    }

    private createSignature(contractObject: PQContractType | Record<any, any>): Record<string, any> {
        const {buyer} = contractObject;
        const supplier = this.getField<PQsupplier | string>(contractObject, "suppliers[0]");
        const fromBuyer = this.isMedicineTemplate(contractObject.contractTemplateName) ? pqSignature.customer : pqSignature.buyer;

        return {
            table: {
                widths: [PDF_HELPER_CONST.ROW_WIDTH_250, "auto"],
                body: [
                    [pqSignature.supplier, fromBuyer],
                    [
                        this.getField(supplier as any, "name", pqSignature.name + " " + pqSignature.suppliers),
                        this.getField(buyer, "name", pqSignature.name + " " + pqSignature.buyers),
                    ],
                    [
                        this.customerLocation(this.getField<AddressType>(supplier as any, "address")) || pqSignature.location,
                        this.customerLocation(this.getField<AddressType>(buyer, "address")) || pqSignature.location,
                    ],
                    [
                        this.getField(supplier as any, "identifier.id", pqSignature.edrpou + " " + pqSignature.suppliers),
                        this.getField(buyer, "identifier.id", pqSignature.edrpou + " " + pqSignature.buyers),
                    ],
                    [
                        this.getField(supplier as any, "signerInfo.iban", pqSignature.iban + " " + pqSignature.suppliers),
                        this.getField(buyer, "signerInfo.iban", pqSignature.iban + " " + pqSignature.buyers),
                    ],
                    [
                        this.getField(supplier as any, "signerInfo.telephone", pqSignature.phone + " " + pqSignature.suppliers),
                        this.getField(buyer, "signerInfo.telephone", pqSignature.phone + " " + pqSignature.buyers),
                    ],
                    [
                        this.getField(supplier as any, "signerInfo.email", `${pqSignature.email} ${pqSignature.suppliers}`),
                        this.getField(buyer, "signerInfo.email", `${pqSignature.email} ${pqSignature.buyers}`),
                    ],
                    [
                        this.getField(supplier as any, "signerInfo.website", pqSignature.website),
                        this.getField(buyer, "signerInfo.website", pqSignature.website),
                    ],
                    [STRING.EMPTY, STRING.EMPTY],
                    [pqSignature.signature, pqSignature.signature],
                    [pqSignature.stamp, pqSignature.stamp],
                ],
            },
            layout: "noBorders",
        };
    }

    private formatAttribute(attributes?: PQattribute[]): string {
        return (attributes || []).reduce((accumulator: string, value) => {
            const field = this.getField(value, "unit.name");
            const unitName = field ? `-${field}` : STRING.EMPTY;

            return accumulator.concat(
                value.name || STRING.EMPTY,
                STRING.MINUS,
                (value.values || STRING.EMPTY).toString(),
                unitName,
                pqBase.newLine,
            );
        }, STRING.EMPTY);
    }

    private formatClassification(classification: ClassificationType): string {
        return [
            this.getField(classification, "scheme"),
            this.getField(classification, "id"),
            this.getField(classification, "description"),
        ].join(STRING.WHITESPACE);
    }

    private formatOlArray([first, ...args]: string[], index: number, itemIndex: number): OlPdfType[] {
        return [
            {
                start: itemIndex + 1,
                separator: [`${index + 1}.`, STRING.DOT],
                ol: [first],
            },
            {
                start: 1,
                separator: [`${index + 1}.${itemIndex + 1}.`, STRING.DOT],
                ol: args,
            },
        ];
    }

    private formatOlItem(olItem: string, listIndex: number, itemIndex: number, indexReducer: number): OlPdfType {
        return {
            separator: [`${listIndex + 1}.`, "."],
            start: itemIndex + 1 - indexReducer,
            ol: [olItem],
        };
    }

    private formatNoMarkerText({text, paths, defaults}: CompoundTextType, contractObject: PQContractType | Record<string, never>): Record<string, any> {
        const preparedText = text.reduce((accum: string, item) => {
            if (this._typeChecker.isNumber(item)) {
                const itemValue = this.getField(contractObject, paths[Number(item)], defaults[Number(item)]);
                return accum.concat(itemValue);
            }

            return accum.concat(item as string);
        }, STRING.EMPTY);

        return {text: preparedText};
    }

    private createSpecificationUnderTableList(contractObject: PQContractType | Record<string, any>): string[] {
        const tableList: string[] = [];
        const listConfig = TemplateToSpecificationListMap.get(contractObject.contractTemplateName) || generalListConfig;

        listConfig.forEach(listItem => {
            tableList.push(
                listItem.reduce(
                    (previousValue: string, currentValue: PQspecificationListItem): string => {
                        if (currentValue.hasOwnProperty("functionName")
                            && this.getField(currentValue, "functionName")
                            && this.getField(contractObject, (currentValue as CompoundStringListItem).path)
                        ) {
                            const preparedValue = this.getField(contractObject, (currentValue as CompoundStringListItem).path);
                            switch (this.getField(currentValue, "functionName")) {
                                case "formatClassification":
                                    return previousValue.concat(this.formatClassification(preparedValue as ClassificationType) || (currentValue as CompoundStringListItem).default);
                                case "customerLocation":
                                    return previousValue.concat(this.customerLocation(preparedValue as AddressType));
                                case "deliveryDateDiff":
                                    if (!(preparedValue as string) || !contractObject.dateSigned) {
                                        return previousValue.concat((currentValue as CompoundStringListItem).default);
                                    }

                                    return previousValue.concat(DateHandler.deliveryDateDiff(
                                        {
                                            startDate: contractObject.dateSigned as string,
                                            endDate: preparedValue as string,
                                        },
                                        (currentValue as CompoundStringListItem).default,
                                    ));
                                case "formatDate":
                                    return previousValue.concat(DateHandler.phpDateFormat(preparedValue as string));
                            }
                        }

                        if (currentValue.hasOwnProperty("path") && this.getField(currentValue, "path")) {
                            return previousValue.concat(this.getField(contractObject, (currentValue as CompoundStringListItem).path, (currentValue as CompoundStringListItem).default));
                        }

                        if (currentValue.hasOwnProperty("text")) {
                            return previousValue.concat((currentValue as TextListItem).text);
                        }

                        return previousValue;
                    },
                    STRING.EMPTY,
                ),
            );
        });

        return tableList;
    }

    private createMedAddition(contractTemplate: string): Record<string, any>[] {
        const text = contractTemplate === TemplateCodesEnum.MEDICINE ? testingGroundsTexts : labAnalysisTexts;
        return [
            {
                text: this.getField(text, "addition2"),
                style: PDF_FILED_KEYS.SPECIFICATION_HEADING,
                pageBreak: "before",
            },
            {
                text: this.getField(text, "toContract"),
                style: PDF_FILED_KEYS.SPECIFICATION_HEADING,
            },
            {
                text: this.getField(text, "from"),
                style: PDF_FILED_KEYS.SPECIFICATION_HEADING,
            },
            {
                text: this.getField(text, "list"),
                style: PDF_FILED_KEYS.HEADING,
                margin: PQ_SPECIFICATION_HEADING_MARGIN,
            },
            {
                type: "none",
                margin: PQ_TESTING_GROUNDS_MARGIN,
                ol: text.groundsList,
            },
        ];
    }

    private getPrice({amount = 0, currency = "", valueAddedTaxIncluded = false}: PQvalue, quantity = 1): string {
        const precision = 2;
        const price = valueAddedTaxIncluded ? this.amountRemoveTax(amount) : amount;

        return price ? `${(price * quantity).toFixed(precision)}${currency}` : "";
    }

    private isMedicineTemplate(templateName:string):boolean {
        return [TemplateCodesEnum.MEDICINE, TemplateCodesEnum.PHARM].includes(templateName as TemplateCodesEnum);
    }

    private amountRemoveTax(amount: number): number {
        const fullPrice = 6;
        const realPricePart = 5;
        return realPricePart * Number(amount) / fullPrice;
    }

    private specificationHeader(contractObject: PQContractType | Record<string, any>, specificationDate: string): Record<string, any>[] {
        const header:Record<string, any>[] = [
            {
                text: pqSpecificationTexts.addition1,
                style: PDF_FILED_KEYS.SPECIFICATION_HEADING,
                pageBreak: "before",
            },
            {
                text: pqSpecificationTexts.toContract,
                style: PDF_FILED_KEYS.SPECIFICATION_HEADING,
            },
        ];

        header.push({
            text: `від ${DateHandler.prepareDateSigned(specificationDate, "р.")}`,
            style: PDF_FILED_KEYS.SPECIFICATION_HEADING,
        });

        if ([TemplateCodesEnum.MEDICINE, TemplateCodesEnum.PHARM].includes(this.getField(contractObject, "contractTemplateName"))) {
            header.push(
                {
                    text: pqSpecificationTexts.specification,
                    style: PDF_FILED_KEYS.HEADING,
                    margin: PQ_LIST_HEADING_MARGIN,
                },
                {
                    text: `${pqSpecificationTexts.toPurchaseAgreement} ${DateHandler.prepareDateSigned(this.getField(contractObject, "dateSigned"), "року")}`,
                    style: PDF_FILED_KEYS.CONTENT,
                },
                {
                    text: `${pqSpecificationTexts.medicineCity} ${DateHandler.prepareDateSigned(this.getField(contractObject, "dateSigned"), "року")}`,
                    margin: PQ_SPECIFICATION_HEADING_MARGIN,
                },
            );
        } else {
            header.push({
                text: pqSpecificationTexts.specification,
                style: PDF_FILED_KEYS.HEADING,
                margin: PQ_SPECIFICATION_HEADING_MARGIN,
            });
        }

        return header;
    }
}
