export const PDF_FILED_KEYS: Record<string, string> = {
    HEADING: "heading",
    HEADING_TITLE: "heading_title",
    DATE: "date",
    CONTENT: "content",
    DESCRIPTION: "description",
    FIELD_TEXT: "field_text",
    FIELD_DESCRIPTION_TEXT: "field_description_text",
    UNDERLINE: "underline",
    TITLE_MEDIUM: "title_medium",
    TITLE_MEDIUM_BOLD: "title_medium_bold",
    TITLE_LARGE: "title_large",
    TABLE_HEAD: "table_head",
    TABLE_DATA: "table_data",
    TABLE_DATA_BOLD: "table_data_bold",
    TABLE_HEAD_ESCO: "table_head_esco",
    TABLE_DATA_ESCO: "table_data_esco",
    TABLE_DATA_BLUE: "table_data_blue",
    SPECIFICATION_HEADING: "specification_heading",
    COMPLAINT_SUBHEADING: "complaint_subheading",
    ITALIC_TEXT: "italic",
    TITLE_LARGE_TENDER_OFFER: "title_large_tender_offer",
};
