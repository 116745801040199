import {TableHeadConfigType} from "~/types/PQ/TextConfigType";
import {TemplateCodesEnum} from "~/types/PQ/TemplateCodes.enum";
import {generalTableHeader} from "~/config/pdf/pq/contractTextConfigs/computersTextsConfig";
import {fuelTableHeader} from "~/config/pdf/pq/contractTextConfigs/fuelTextsConfig";

export const TemplateToTableHead = new Map<string, TableHeadConfigType>()
    .set(TemplateCodesEnum.FRUIT, generalTableHeader)
    .set(TemplateCodesEnum.COMPUTER, generalTableHeader)
    .set(TemplateCodesEnum.OTHER, generalTableHeader)
    .set(TemplateCodesEnum.MEDICINE, generalTableHeader)
    .set(TemplateCodesEnum.PHARM, generalTableHeader)
    .set(TemplateCodesEnum.GAS, fuelTableHeader);
