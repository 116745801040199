import {LoaderStrategyInterface} from "~/services/PDF/P7SLoader/LoaderStrategyInterface";
import {AbstractLoaderStrategy} from "~/services/PDF/P7SLoader/AbstractLoaderStrategy";
import {P7SLoadResultType} from "~/types/pdf/P7SLoadResultType";
import {ENCODING} from "~/constants/encoding";
import {PdfDocumentConfigType} from "~/types/pdf/PdfDocumentConfigType";
import {PdfTemplateTypes} from "~/services/PDF/PdfTemplateTypes";
import {ComplaintType} from "~/types/complaints";
import {Assert} from "~/utils/Assert";
import {messages} from "~/config/messages";
import {DocumentType} from "~/types/Tender/DocumentType";
import {STRING} from "~/constants/string";
import {DateHandler} from "~/utils/DateHandler";

export class ComplaintLoader extends AbstractLoaderStrategy implements LoaderStrategyInterface {
    public async load(object: ComplaintType, config: PdfDocumentConfigType): Promise<P7SLoadResultType> {
        Assert.isDefined(object && object.documents.length, messages.error.documentListUndefined);
        const url = object && object.documents.length ? this.getDocumentUrl(object) : STRING.EMPTY;
        const file = await this.getData(this.getDocumentUrl(object));

        return {
            file,
            type: PdfTemplateTypes.COMPLAINT,
            encoding: config.encoding || ENCODING.UTF_16,
            url,
        };
    }

    private getDocumentUrl({documents: documentList}: ComplaintType): string {
        const [document] = documentList.filter((doc: DocumentType) => doc.title === "sign.p7s")
            .sort((...args) => DateHandler.dateModifiedDiff(...args))
            .slice(-1);
        Assert.isDefined(document, messages.error.undefinedDocumentTitle);

        return document.url;
    }
}
