import {pqBase, pqTexts} from "~/widgets/pq/configs/pqTexts";
import {PdfItemEnum, TextConfigType} from "~/widgets/pq/types/TextConfigType";
import {pqGenericTexts} from "~/widgets/pq/templates/generic/configs/pqGenericTexts";
import {DEFAULT_TEXT_FIELDS, STRING} from "~/constants/string";
import {FormattingFunctionsEnum} from "~/widgets/pq/services/Formating/config/FormattingFunctions.enum";

export const fruit2ContractConfig: TextConfigType = {
    subject: [
        pqTexts.subject.obligationsToBuyer,
        pqTexts.subject.meetSpecification,
        pqTexts.subject.ownership,
        pqTexts.subject.procurementAmount,
        pqTexts.subject.noLawContradiction,
        pqTexts.subject.licenseCopy,
    ],
    price: [
        pqTexts.price.currency,
        pqTexts.price.complectation,
        pqTexts.price.noPriceRaise,
        pqTexts.price.allowAmountDecrease,
    ],
    paymentOrder: [
        pqTexts.paymentOrder.article23,
        pqTexts.paymentOrder.financing,
        pqTexts.paymentOrder.temporalFundFreeze,
    ],
    deliveryTerms: [
        {
            text: [pqGenericTexts.paymentOrder.buyerPays, 0],
            paths: ["items"],
            defaults: [DEFAULT_TEXT_FIELDS.UNDERSCORES_16],
            pdfType: PdfItemEnum.LIST_ITEM,
            functionName: [FormattingFunctionsEnum.PAYMENT_DETAILS],
        },
        pqTexts.deliveryTerms.place,
        pqTexts.deliveryTerms.undeclaredGoods,
        pqTexts.deliveryTerms.improperDocumentation,
        pqTexts.deliveryTerms.transport,
        pqTexts.deliveryTerms.driver,
        pqTexts.deliveryTerms.invoice,
        pqTexts.deliveryTerms.notMeetRequirements,
        pqTexts.deliveryTerms.goodsReturn,
        pqTexts.deliveryTerms.goodsReplacement,
        pqTexts.deliveryTerms.defienceElimination,
        pqTexts.deliveryTerms.paymentDecline,
        pqTexts.deliveryTerms.completed,
    ],
    guarantee: [
        pqTexts.guarantee.correspondentQuality,
        [
            pqTexts.guarantee.supplierGuarantee,
            pqTexts.guarantee.defectedGood,
            pqTexts.guarantee.replacementTerm,
        ],
        pqTexts.guarantee.assortment,
        pqTexts.guarantee.expirationDate,
        pqTexts.guarantee.lowQualityGood,
        pqTexts.guarantee.qualityReception,
        pqTexts.guarantee.deliveryPacking,
        pqTexts.guarantee.supplementDocuments,
        pqTexts.guarantee.securementDocuments,
        pqTexts.guarantee.implementationTerms,
    ],
    acceptanceOrder: [
        pqTexts.acceptanceOrder.unload,
        pqTexts.acceptanceOrder.specificationCompliance,
        pqTexts.acceptanceOrder.transfer,
        pqTexts.acceptanceOrder.transferDecline,
        pqTexts.acceptanceOrder.ownershipRights,
        pqTexts.acceptanceOrder.packing,
        pqTexts.acceptanceOrder.defected,
    ],
    rights: [
        pqTexts.rights.supplierUndertakes,
        pqTexts.rights.supplierHasRight,
        pqTexts.rights.buyerUndertakes,
        pqTexts.rights.buyerHasRight,
        pqTexts.rights.sidesUndertake,
    ],
    responsibilities: [
        pqTexts.responsibilities.sidesResponsibilities,
        pqTexts.responsibilities.qualityViolation,
        pqTexts.responsibilities.periodViolation,
        pqTexts.responsibilities.paymentViolation,
        pqTexts.responsibilities.penalties,
        pqTexts.responsibilities.rejection,
        pqTexts.responsibilities.paymentDecrease,
    ],
    disputes: [
        pqTexts.disputes.communication,
        pqTexts.disputes.court,
    ],
    forceMajeure: [
        pqTexts.forceMajeure.onset,
        pqTexts.forceMajeure.statement,
        pqTexts.forceMajeure.events,
        pqTexts.forceMajeure.notification,
        pqTexts.forceMajeure.noNotification,
        pqTexts.forceMajeure.continuePeriod,
        pqTexts.forceMajeure.contractExtension,
        pqTexts.forceMajeure.tradeChamber,
    ],
    anticorruption: [
        pqTexts.anticorruption.noStimulation,
        pqTexts.anticorruption.immediateNotification,
        pqTexts.anticorruption.anticorruptionLaw,
        pqTexts.anticorruption.confidentiality,
    ],
    messaging: [
        pqTexts.messaging.electronic,
        pqTexts.messaging.addresses,
        {
            text: [pqTexts.messaging.fromSupplier, 0, STRING.DELIMITER.DOUBLE_NEWLINE],
            paths: ["suppliers[0].signerInfo.email"],
            defaults: [pqBase.eAddress],
            pdfType: PdfItemEnum.TEXT,
        },
        {
            text: [pqTexts.messaging.fromBuyer, 0, STRING.DELIMITER.DOUBLE_NEWLINE],
            paths: ["buyer.signerInfo.email"],
            defaults: [pqBase.eAddress],
            pdfType: PdfItemEnum.TEXT,
        },
        pqTexts.messaging.authorizedPerson,
    ],
    signOrder: [
        pqTexts.signOrder.electronicTrust,
        pqTexts.signOrder.deedOfTermination,
        pqTexts.signOrder.lossOfDocument,
        pqTexts.signOrder.fullPower,
    ],
    contractAction: [
        [pqTexts.contractAction.takesEffect, pqTexts.contractAction.takesEffectSub],
        pqTexts.contractAction.elongated,
        pqTexts.contractAction.refusal,
        pqTexts.contractAction.additionals,
    ],
    finalStatements: [
        pqTexts.finalStatements.conditionsChange,
        pqTexts.finalStatements.byAgreement,
        pqTexts.finalStatements.thirdParties,
        pqTexts.finalStatements.changeOfLocation,
        pqTexts.finalStatements.unRegulated,
        pqTexts.finalStatements.taxStatus,
        pqTexts.finalStatements.personalData,
        pqTexts.finalStatements.noChangeAfterSign,
    ],
    additionals: [
        pqTexts.additionals.additionalContracts,
        [pqTexts.additionals.integralPart, pqTexts.additionals.specification],
    ],
};
