import axios from "axios";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {Pdfmake} from "~/globals";
import {XMLParser} from "~/services/Dom/XMLParser";
import {ErrorExceptionCore} from "~/widgets/ErrorExceptionCore/ErrorExceptionCore";
import {PDFInterface} from "~/services/PDF/PDFInterface";
import {TenderResponseType} from "~/types/Tender/TenderResponseType";
import {DocumentManager} from "~/services/PDF/document/DocumentManager";
import {Base64} from "~/utils/Base64";
import {Assert} from "~/widgets/ErrorExceptionCore/Assert";
import {ERROR_MESSAGES} from "~/widgets/ErrorExceptionCore/configs/messages";
import {PdfTypes} from "~/services/PDF/PdfTypes";
import {PdfConfigType} from "~/types/pdf/PdfConfigType";
import {LoaderManager} from "~/services/PDF/P7SLoader/LoaderManager";
import {PdfDocumentConfigType} from "~/types/pdf/PdfDocumentConfigType";
import {PdfObjectType} from "~/types/pdf/PdfObjectType";
import {DataTypeValidator} from "~/services/DataTypeValidator/DataTypeValidator";
import {ValidationTypes} from "~/services/DataTypeValidator/ValidationTypes";
import {EdsInterface} from "services/EdsInterface";
import {DictionaryCollector} from "~/services/DictionaryCollector/DictionaryCollector";
import {ENCODING} from "~/constants/encoding";
import {SignerType} from "types/sign/SignerType";
import {PdfTemplateTypes} from "~/services/PDF/PdfTemplateTypes";
import {fonts} from "~/fonts/times";
import {SIGN_TO_DOC_FRAME_ID, STRING} from "~/constants/string";
import {TemplateCodesEnum} from "~/widgets/pq/types/TemplateCodes.enum";
import {FetchTender} from "~/widgets/pq//services/receivingData/FetchTender";
import {PQContractType} from "~/widgets/pq/types/PQTypes";
import {ERROR_CODES} from "~/widgets/ErrorExceptionCore/constants/ERROR_CODES.enum";

export class PDF implements PDFInterface {
    readonly TYPES = PdfTypes;
    readonly TEMPLATES = TemplateCodesEnum;
    private readonly base64 = new Base64();
    private readonly xmlParser = new XMLParser();
    private readonly documentManager = new DocumentManager(this.xmlParser);
    private readonly dataTypeValidator = new DataTypeValidator();
    private readonly pdf = pdfMake as Pdfmake;
    private object?: PdfObjectType;
    private documentType = PdfTypes.TICKET;
    private eds?: EdsInterface;

    init(eds: EdsInterface): void {
        this.eds = eds;
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        pdfMake.vfs["Times-New-Roman-Regular.ttf"] = fonts["Times-Regular.ttf"];
        pdfMake.vfs["Times-New-Roman-Bold.ttf"] = fonts["Times-Bold.ttf"];
        pdfMake.vfs["Times-New-Roman-Italic.ttf"] = fonts["Times-Italic.ttf"];

        pdfMake.fonts = {
            Roboto: {
                normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
                bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
                italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
                bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
            },
            Times: {
                normal: "Times-New-Roman-Regular.ttf",
                bold: "Times-New-Roman-Bold.ttf",
                italics: "Times-New-Roman-Italic.ttf",
                bolditalics: "Times-New-Roman-Italic.ttf",
            },
        };
    }

    async setConfig({url, type}: PdfConfigType): Promise<void | ErrorExceptionCore> {
        try {
            this.documentType = type || this.documentType;

            if (type === PdfTypes.PQ && url === STRING.EMPTY) {
                return;
            }

            this.dataTypeValidator.validate(url, ValidationTypes.STRING, ERROR_MESSAGES.INVALID_PARAMS.undefinedUrl);
            const {data: {data: payload}}: TenderResponseType = await axios.get(url);
            this.object = payload;
        } catch (error) {
            throw new ErrorExceptionCore(error);
        }
    }

    async open(config: PdfDocumentConfigType): Promise<void | ErrorExceptionCore> {
        this.dataTypeValidator.validate(config.title, ValidationTypes.STRING);

        const document = await this.create(config);

        try {
            this.pdf.createPdf(document).open();
        } catch (error) {
            throw new ErrorExceptionCore({
                message: error?.message,
                originalError: error,
                code: ERROR_CODES.PDF_GENERATION_FAILED,
            });
        }

    }

    /**
     * Get document for testing
     */
    async getDocument(config: PdfDocumentConfigType): Promise<Record<string, any>> {
        this.dataTypeValidator.validate(config.title, ValidationTypes.STRING);

        const document = await this.create(config);
        return document;
    }

    async getIframe(config: PdfDocumentConfigType): Promise<void | ErrorExceptionCore> {
        const documentFile = await this.create(config);

        try {

            this.pdf.createPdf(documentFile).getDataUrl(dataUrl => {
                const targetElement: HTMLElement = document.getElementById(SIGN_TO_DOC_FRAME_ID) as HTMLElement;
                const iframe = document.createElement("iframe");
                iframe.src = dataUrl;
                iframe.width = "100%";
                iframe.height = "100%";
                targetElement.appendChild(iframe);
            });
        } catch (error) {
            throw new ErrorExceptionCore({
                message: error?.message,
                originalError: error,
                code: ERROR_CODES.PDF_GENERATION_FAILED,
            });
        }
    }

    async save(config: PdfDocumentConfigType): Promise<void | ErrorExceptionCore> {
        this.dataTypeValidator.validate(config.title, ValidationTypes.STRING);

        const document = await this.create(config);

        try {
            this.pdf.createPdf(document).download(`${config.title}.pdf`);
        } catch (error) {
            throw new ErrorExceptionCore({
                message: error?.message,
                originalError: error,
                code: ERROR_CODES.PDF_GENERATION_FAILED,
            });
        }
    }

    private async create(config: PdfDocumentConfigType): Promise<Record<string, any>> {
        try {
            if (this.documentType === PdfTypes.PQ) {
                const signers = [] as SignerType[];
                const dictionaries = await new DictionaryCollector().load(PdfTemplateTypes.PQ);
                this.object = await FetchTender.getTenderForContract(this.object as PQContractType);

                this.documentManager.setDocumentType(PdfTemplateTypes.PQ);

                return this.documentManager.getDocumentData(config.contractTemplateName || STRING.EMPTY, signers, dictionaries, STRING.EMPTY, this.object);
            }

            Assert.isDefined(this.object, ERROR_MESSAGES.VALIDATION_FAILED.undefinedObject);
            Assert.isDefined(this.eds, ERROR_MESSAGES.INVALID_PARAMS.libraryInit, ERROR_CODES.INVALID_PARAMS);

            const loaderManager = new LoaderManager(this.base64, axios);
            loaderManager.setLoaderType(this.documentType);
            const {file, type, encoding, url} = await loaderManager.getData(this.object, config);
            let data: string = STRING.EMPTY;
            let signers = [] as SignerType[];

            if (PdfTemplateTypes.EDR === type && url) {
                const response = await axios.get(url);
                data = response.data as string;
            } else if (type !== PdfTemplateTypes.NAZK) {
                ({data, signers} = await this.getDataFromSign(file, encoding, [], type));
            } else if (url) {
                ({data} = await axios.get(url));
                data = JSON.stringify(data);
            }

            if (type === PdfTemplateTypes.COMPLAINT && config.tender) {
                const {data: {data: payload}}: TenderResponseType = await axios.get(config.tender);
                this.object = payload;
            }

            Assert.isDefined(data, ERROR_MESSAGES.INVALID_SIGNATURE.documentEncoding, ERROR_CODES.INVALID_SIGNATURE);
            const dictionaries = await new DictionaryCollector().load(type);

            this.documentManager.setDocumentType(type);
            return this.documentManager.getDocumentData(data, signers, dictionaries, url, this.object);
        } catch (error) {
            throw new ErrorExceptionCore(error);
        }
    }

    private async getDataFromSign(file: string, encoding: ENCODING | undefined, checkedEncodings: Array<ENCODING>, type: string): Promise<{
        data: any
        signers: any
    }> {
        if (checkedEncodings.sort().toString() === Object.values(ENCODING).sort().toString()) {
            return Promise.resolve({data: null, signers: null});
        }

        Assert.isDefined(this.eds, ERROR_MESSAGES.INVALID_PARAMS.libraryInit, ERROR_CODES.INVALID_PARAMS);

        try {
            const response = await this.eds.verify(file, encoding);

            if (type === PdfTemplateTypes.KVT || type === PdfTemplateTypes.XML) {
                if (response && this.tryParseXMLObject(response["data"])) {
                    return response;
                }
            } else if (response && this.tryParseJSONObject(response["data"])) {
                return response;
            }
        } catch (error) {
            throw new ErrorExceptionCore({
                code: ERROR_CODES.INVALID_SIGNATURE,
                message: error?.message,
                originalError: error,
            });
        }

        let leftEncodings = undefined;

        if (encoding) {
            checkedEncodings.push(encoding);
            leftEncodings = Object.values(ENCODING).filter(x => !checkedEncodings.includes(x));
        }

        return this.getDataFromSign(file, leftEncodings ? leftEncodings.pop() : undefined, checkedEncodings, type);
    }

    private tryParseJSONObject(jsonString: string): boolean {
        try {
            const o = JSON.parse(jsonString);
            return o && typeof o === "object";
        } catch (e) {
            return false;
        }
    }

    private tryParseXMLObject(xmlString: string): boolean {
        try {
            const parser = new DOMParser();
            const o = parser.parseFromString(xmlString, "application/xml");
            return Boolean(o && typeof o === "object");
        } catch (e) {
            return false;
        }
    }
}
